import React from "react";
import { ReportBuilder } from "react-report-builder";
import { PeekdataApi } from "peekdata-datagateway-api-sdk";

import "react-datepicker/dist/react-datepicker.css";
//import "react-table/react-table.css";
import "react-report-builder/lib/main.css";
import { useSelector } from "react-redux";



const SMSEmailReport = () => {
    const state = useSelector(state => state);
    const peekdataApi = new PeekdataApi({
        baseUrl: state.projectdetail.API + "/ReportBuilder",
        timeout: 60000,
    });
    return (<ReportBuilder peekdataApi={peekdataApi} />);
}
export default SMSEmailReport;