import { useState, useContext, useEffect } from "react"
import { Card, Button, Modal, CloseButton, Spinner } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import SMSEmailTemplateForm from './SMSEmailTemplateForm';
import Icon from "../../controls/Icons/Icon";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

const SMSEmailTemplate = () => {
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState({
        Id: 0,
    });
    const [columns, setColumns] = useState([

        {
            field: "tblSMSEmailTemplateType.SMSEmailTemplateType",
            title: "SMS Email Template",
            sortable: true,
        },        
        {
            dataField: "remove",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (<div>
                    {state.access.edit ? <Button
                        className="btn-xs me-2 mb-2 p-0" variant="link"
                        onClick={() => handleEdit(row)}
                    ><Icon name="BsPencilFill" className='fs-5' /></Button> : ""}

                    {state.access.delete ? <Button variant="link"
                        className="btn-xs mb-2 p-0"
                        onClick={() => handleDelete(row)}
                    >
                        <Icon name="BsTrash" className='fs-5' />
                    </Button> : ""}
                </div>
                );
            },
        }
    ]);

    const handleEdit = (res) => {
        const emailContentContent = htmlToDraft(res.emailContent);
        const emailContentContentState = ContentState.createFromBlockArray(emailContentContent.contentBlocks);
        const emailContentEditorState = EditorState.createWithContent(emailContentContentState);
        res.emailContentDraft = emailContentEditorState;

        setInputValue(res)
        setShowModal(true)
    };

    const handleDelete = ((res) => {
        window.confirm("Do you want to delete the smsemailtemplate?", function () {
            setLoading(true);
            let obj = {};
            obj.Id = res.Id;
            fetch(state.projectdetail.API + "/smsemailtemplate/delete?Id=" + res.Id, {   
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                {
                    if (res.Succeeded) {
                        window.alert("Deleted successfuly");
                        //setRefreshGrid(!refreshGrid);
                        setRefreshGrid(r => !r);
                    }
                    else {
                        window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                    }
                }
            }).catch((err) => {
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        });
    });

    const [options, setOptions] = useState({
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "createdAt",
        sortOrder: "desc",
    });
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleAdd = () => {
        setInputValue([])
        setShowModal(true)
    };
    const handleAfter = () => {
        handleClose();
        setRefreshGrid(!refreshGrid);
    };
    return (
        <>
            <h4 className='form-heading mb-4'>
                SMS Email Template
                <div className='d-inline-block float-end'>
                    <Button onClick={handleAdd}>Add <Icon name="BsPersonPlus" /></Button>&nbsp;
                </div>
            </h4>
            <Card>
                <Card.Body>
                    {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                    <DataGrid url={state.projectdetail.API + "/smsemailtemplate/grid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
            <Modal show={showModal} size="lg" onHide={handleClose}>
                <Modal.Header>
                    {inputValue.Id ? "Edit " : "Add "}SMSEmailTemplate
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <SMSEmailTemplateForm afterSave={handleAfter} _inputValue={inputValue} onCancel={handleClose} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SMSEmailTemplate;
