import React, { memo, useEffect, useState } from 'react'
import { Row, Col, Card, Badge, Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { POAction } from '../../../Reducer/POReducer';

function PODetailView() {
    const state = useSelector(state => state);
    const urlParams = useParams();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {

        setLoading(true);
        if (state.po.Id == 0 && urlParams.hasOwnProperty("id")) {
            fetch(state.projectdetail.API + "/POAdmin/get?Id=" + urlParams.id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(response => response.json()).then((res) => {
                {
                    debugger
                    dispatch({ type: POAction.setPO, payload: res });

                }
            }).catch((err) => {
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [])

    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "InvoiceDate",
        sortOrder: "asc",
    };
    return (
        <div>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <Badge variant="success">{state.po.PONo}</Badge>
                        </Col>
                        <Col md={6}>
                            {state.po.objProject && state.po.objProject.ProjectName}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Nav variant="tabs" defaultActiveKey="/home">
                                <Nav.Item>
                                    <Nav.Link href="/home">Invoices</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}
export default memo(PODetailView)