import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import SidebarMenu from './controls/Menu/SidebarMenu';
import { Nav, Navbar, Row, Col, Button, Collapse, Card, Container, Modal } from 'react-bootstrap'
import "./SliderLayout.css"
import Menu from './controls/Menu/Menu';
import AlertPrompt from './controls/Prompt/AlertPrompt';
import ConfirmPrompt from './controls/Prompt/ConfirmPrompt';
import Icon from './controls/Icons/Icon';
import Footer from './Footer';

const SliderLayout = ({ heading, outlet }) => {
    window.alert = (msg, onYes, icon, ButtonText, varient) => {
        setAlertOptions(
            {
                show: true,
                message: msg,
                onYes: onYes,
                icon: icon,
                ButtonText: ButtonText,
                varient: varient,
            }
        );
    }
    window.hideAlert = () => {
        setAlertOptions({ show: false, message: "", onYes: null, icon: "" });
    }


    window.confirm = (msg, onYes, onNo, icon, YesButtonText, NoButtonText, confirmCheckbox, confirmCheckboxText) => {
        setConfirmOptions(
            {
                show: true,
                message: msg,
                onYes: onYes,
                onNo: onNo,
                icon: icon,
                YesButtonText: YesButtonText,
                NoButtonText: NoButtonText,
                confirmCheckbox: confirmCheckbox,
                confirmCheckboxText: confirmCheckboxText
            }
        );
    }
    window.hideConfirm = () => {
        setConfirmOptions({ show: false, message: "", onYes: null, icon: "" });
    }

    const [alertOptions, setAlertOptions] = useState({
        message: "",
        icon: "",
        show: false,
        onYes: function () { },
        ButtonText: "OK",
        varient: "success",
    })

    const [confirmOptions, setConfirmOptions] = useState({
        message: "",
        icon: "",
        show: false,
        onYes: function () { },
        onNo: function () { },
        YesButtonText: "Yes",
        NoButtonText: "No"
    })

    const state = useSelector(state => state);

    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [sideBarOpenMobile, setSideBarOpenMobile] = useState(false);
    const [mobieView, setMobieView] = useState(false);
    const handleResize = () => {
        if (window.outerWidth <= 991) {
            setSideBarOpen(false);
            setMobieView(true);
        }
        else {
            setMobieView(false);
        }
    }
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize, false);
    }, []);

    const showHideSidebar = () => {
        setSideBarOpen(!sideBarOpen);
    }
    return (
        <Container fluid className={(sideBarOpen ? " sidebar-open " : "") + (mobieView ? " mobile-view " : "")}>
            {state.access.showMenu ?
                <Card className='sidebar-header'>
                    <Menu mobieView={mobieView} show={state.access.showMenu} showHideSidebar={showHideSidebar} open={sideBarOpen} showHideSidebarMobile={showHideSidebar} openMobile={showHideSidebar} />
                </Card>
                : ""}
            <Card className={"sidebar-menu"}>
                <Nav.Item className='sidebar-menu-heading bg-link'>
                    <Nav.Link className={"heading " + (sideBarOpen ? " pt-1 ms-3 " : " pt-0 ") + " mb-0 d-none d-md-block d-lg-block d-xl-block"} onClick={showHideSidebar}>
                        {(sideBarOpen ? <span><Icon name="AiOutlineMenuFold" />&nbsp;Menu</span> : <Icon name="AiOutlineMenuUnfold" />)}
                    </Nav.Link>
                </Nav.Item>
                {/* <a className={"" + (sideBarOpen ? " ps-3 p-2 pb-3 pt-3 " : " ps-4 p-2 pb-3 pt-3 ") + " mb-0 d-none d-md-block d-lg-block d-xl-block"}>
                    {(sideBarOpen ? <span><Icon name="AiOutlineMenuFold"/>&nbsp;Menu</span> : <Icon name="AiOutlineMenuUnfold"/>)}
                </a> */}
                <div className={'sidebar-menu-content h-100'}>
                    <SidebarMenu heading={heading} open={sideBarOpen} mobieView={mobieView} />
                </div>
            </Card>
            <div className={'sidebar-content p-0 '}>
                <div className={'sidebar-content-body ' + (mobieView ? " p-2 " : " p-5 ")}>
                    {alertOptions.show ?
                        <AlertPrompt options={alertOptions} />
                        : ""}

                    {confirmOptions.show ?
                        <ConfirmPrompt options={confirmOptions} />
                        : ""}
                    {outlet}
                </div>
                <Footer />
            </div>
            <div onClick={showHideSidebar} className={((mobieView && sideBarOpen) ? "mobile-sidebar-menu-open " : "")}></div>
            {/* <Row className='mr-0 h-100 min-vh-100'>
                <Col lg={sideBarOpen ? 2 : 1} md={sideBarOpen ? 3 : 1} className={((mobieView && !sideBarOpenMobile) ? "open" : "") + (sideBarOpen ? " open " : "  ") + " p-0 sidebar-menu"}>
                    <Card className={(sideBarOpen ? " pe-3 ps-3 pt-3 " : "  ") + ' min-vh-100'}>
                        <h4 className={"  heading text-uppercase " + (sideBarOpen ? " ps-2 p-2 pb-3 pt-3 " : "") + " mb-0 d-none d-md-block d-lg-block d-xl-block"}>
                            {mobieView ? "" : (sideBarOpen ? state.projectdetail.ProjectName : state.projectdetail.ProjectLogo)}
                        </h4>
                        <Navbar>
                            <SidebarMenu heading={heading} open={sideBarOpen} />
                        </Navbar>
                    </Card>
                </Col>
                <Col lg={sideBarOpen ? 10 : 11} md={sideBarOpen ? 9 : 11} className={"p-0 sidebar-content " + (sideBarOpen ? "" : " sidemenu-opened ")}>
                    <Container fluid>
                        {state.access.showMenu ? <Row>
                            <Col className='top-menu-card sidebar-content-header p-0'>
                                <Card>
                                    <Menu show={state.access.showMenu} showHideSidebar={showHideSidebar} open={sideBarOpen} showHideSidebarMobile={showHideSidebarMobile} openMobile={sideBarOpenMobile} />
                                </Card>

                            </Col>
                        </Row> 
                        : ""}
                        <Row>
                            <Col className={'p-lg-5 p-md-5 p-xl-5 p-3 sidebar-content-body'}>
                                {alertOptions.show ?
                                    <AlertPrompt options={alertOptions} />
                                    : ""}

                                {confirmOptions.show ?
                                    <ConfirmPrompt options={confirmOptions} />
                                    : ""}
                                <div className={((mobieView && !sideBarOpenMobile) ? "mobile-sidebar-menu-open " : "")}></div>
                                <Outlet />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row> */}
        </Container >
    );
}

export default SliderLayout;
