import React, { useState } from 'react'
import { Form, Row, Col, Button, Spinner, Modal, CloseButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { POAction } from '../../../Reducer/POReducer';
import { convertDateToSet } from '../../../Utils';
import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
import FormSmartText from '../../controls/FormControls/FormSmartText';

import Icon from '../../controls/Icons/Icon';
import ServerError from '../../controls/ServerError';
import PODetailsForm from './PODetailsForm';
import VendorForm from '../Vendor/VendorForm';
export default function POForm({ afterSave, onCancel }) {
    const state = useSelector(state => state);

    const [inputValueVendor, setInputValueVendor] = useState({
    });
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleAdd = () => {
        setInputValueVendor([])
        setShowModal(true)
    };


    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const setInputValue = function (data) {
        dispatch({
            type: POAction.setPO, payload: data
        });
    };

    const [serverError, setServerError] = useState({});
    const handleProjectSelect = ((event) => {
        const selectedOption = event.target.selectedOptions[0];
        const selecteText = selectedOption.getAttribute("data-ponumberformat") || "";
        const PaymentTerms = selectedOption.getAttribute("data-paymentterms") || "";
        const TermConditions = selectedOption.getAttribute("data-termconditions") || "";
        setInputValue({ "PONumberFormat": selecteText, PaymentTerms: PaymentTerms, TermConditions: TermConditions });
        handleChange(event);
    });
    const handleChange = ((e) => {

        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue({ [name]: value });
    });
    const handleAutoCompleteSelect = ((item) => {
        debugger
        setInputValue({ "FkvendorId": item.Id, "vendor": item });        
    });

    const handleAddvendor = () => {
        setInputValueVendor([])
        setShowModal(true)
    };
    const handleAfter = (res) => {
        if (res.Succeeded) {
            setInputValue({ "FkvendorId": res.Data.Id });
            setInputValue({ "vendor": res.Data });
            window.alert("Vendor saved successfully");
            handleClose();
        }
        else {
            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
    };
    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            fetch(state.projectdetail.API + "/POAdmin/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(state.po)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    dispatch({ type: POAction.resetPO });
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    })
    const formatRenderItem = () => {

    }
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {state.po.PONo ?
                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label className="PopupHeading">PO Number<code className='required-symbol'></code></Form.Label>:
                                <Form.Label className="PopupHeading">{state.po.PONo}<code className='required-symbol'></code></Form.Label>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Form.Group>

                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
                : ""}
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Project<code className='required-symbol'>*</code></Form.Label>
                            <DynamicDropdownSearchable addAttr={["PONumberFormat", "PaymentTerms", "TermConditions"]}
                                from={state.projectdetail.API + "/Project/GetAll"} label="ProjectName" value="Id" fetchType="get"
                                name="ProjectId" onDropdownChange={handleProjectSelect} defaultval={state.po.ProjectId} />
                            {validated && !state.po.ProjectId && <div><small className="text-danger">Project is required</small></div>}
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>PO Template<code className='required-symbol'>*</code></Form.Label>
                            <DynamicDropdownSearchable from={state.projectdetail.API + "/POTemplate/GetActive"} label="POTemplateName" value="Id" fetchType="get" name="FkPOTemplateId" onDropdownChange={handleChange} defaultval={state.po.FkPOTemplateId} />
                            {validated && !state.po.FkPOTemplateId && <div><small className="text-danger">PO template is required</small></div>}
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>PO Number Format<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="text" disabled={true} name="PONumberFormat" value={state.po.PONumberFormat} onChange={handleChange} autoComplete="false" disabled />
                            <Form.Control.Feedback type="invalid">PO Number Format is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Distinct ID<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="text" name="DistinctID" value={state.po.DistinctID} onChange={handleChange} autoComplete={false} />
                            <Form.Control.Feedback type="invalid">Distinct ID is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>PO From</Form.Label>
                            <Form.Control type="text" name="POFrom" value={state.po.POFrom} onChange={handleChange} autoComplete="false" disabled />
                            <Form.Control.Feedback type="invalid">PO From is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>PO From Address</Form.Label>
                            <Form.Control type="text" name="POFrom" value={state.po.FromAddress} onChange={handleChange} autoComplete="false" disabled />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>PO To<code className='required-symbol'>*</code></Form.Label>
                            <Button
                                className="btn-xs me-2 mb-2 p-1"
                                variant="link"
                                onClick={handleAddvendor}>
                                <Icon name="AiOutlineUserAdd" />
                            </Button>
                            <FormSmartText
                                name="FkvendorId"
                                from={state.projectdetail.API + "/Vendor/GetSearch"}
                                formatParams={(q) => {
                                    return { "text": q }
                                }}
                                label="VendorName"
                                value="Id"
                                fetchType="get"
                                onSelect={handleAutoCompleteSelect}
                                selectedObj={state.po.vendor}
                                formatRenderItem={(obj) => {
                                    return <span>{obj.VendorName}, {obj.VendorEmailID}, {obj.VendorAddress}</span>
                                }}
                            />
                            {validated && !state.po.FkvendorId && <div><small className="text-danger">PO To is required</small></div>}                            
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>PO Date<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="date" name="PODate" value={convertDateToSet(state.po.PODate)} onChange={handleChange} autoComplete="false" />
                            <Form.Control.Feedback type="invalid">PO date is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>


            </Row>

            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Terms & Conditions<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control as="textarea" type="text" name="TermConditions" value={state.po.TermConditions} onChange={handleChange} autoComplete="false" />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Payment Terms<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control as="textarea" type="text" name="PaymentTerms" value={state.po.PaymentTerms} onChange={handleChange} autoComplete="false" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <PODetailsForm data={state.po.PODetails} />
                </Col>
            </Row>
            <Row className="mb-3">

                <Col md={4}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Quantity<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control type="number" onWheel={(e) => e.target.blur()} required name="Quatity" value={state.po.Quatity} onChange={handleChange} autoComplete="false" disabled />
                            <Form.Control.Feedback type="invalid">Total Amount is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={4}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Tax<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control type="number" onWheel={(e) => e.target.blur()} required name="Tax" value={state.po.Tax} onChange={handleChange} autoComplete="false" disabled />
                            <Form.Control.Feedback type="invalid">Tax is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={4}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Total Amount<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control type="number" onWheel={(e) => e.target.blur()} required name="TotalAmount" value={state.po.TotalAmount} onChange={handleChange} autoComplete="false" disabled />
                            <Form.Control.Feedback type="invalid">Total amount is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <ServerError error={serverError} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className='text-center'>
                    <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                        Cancel
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>&nbsp;&nbsp;
                    <Button variant="primary" type="submit" disabled={loading}>
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header>
                    <h4>{inputValueVendor.fkvendorid ? "Edit " : "Add "} User</h4>
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <VendorForm _inputValue={inputValueVendor} afterSave={handleAfter} onCancel={handleClose} />
                </Modal.Body>
            </Modal>
        </Form>

    )
}
