import React, { useEffect, useRef, useState } from 'react'
import { Form, Row, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import CurrencyLabel from '../../controls/CurrencyLabel';
import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
import FileUpload from '../../controls/FileUpload/FileUpload';
import Icon from '../../controls/Icons/Icon';
import ServerError from '../../controls/ServerError';
export default function InvoiceForm({ _inputValue, afterSave, onCancel }) {
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        ProjectId: "",
        fkPOId: "",
        Id: 0,
        InvoiceNo: "",
        InvoiceDate: "",
        InvoiceAmount: "",
    });
    const [PODetail, setPODetail] = useState({
        POTo: "",
        TotalAmount: "",
    });
    const [serverError, setServerError] = useState({});
    const handlePOChange = ((event) => {
        const selectedOption = event.target.selectedOptions[0];
        const selectedPOTo = selectedOption.getAttribute("data-POTo");
        const selectedPOAmount = selectedOption.getAttribute("data-TotalAmount");
        setPODetail(c => ({ ...c, ["POTo"]: selectedPOTo, ["TotalAmount"]: selectedPOAmount }));
        setInputValue(c => ({ ...c, ["InvoiceAmount"]: selectedPOAmount }));
        handleChange(event);
    });
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);

    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            FileUploadRef.current.upload(function (filename) {
                debugger;
                let data = JSON.parse(JSON.stringify(inputValue));
                if (filename)
                    if (Array.isArray(filename))
                        data.InvoiceDocument = filename.join(",");
                    else
                        data.InvoiceDocument = filename;

                fetch(state.projectdetail.API + "/InvoiceAdmin/save", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }).then(response => response.json()).then((res) => {
                    if (res.Succeeded) {
                        setValidated(false);
                        setInputValue({
                            ProjectId: "",
                            fkPOId: "",
                            Id: 0,
                            InvoiceNo: "",
                            InvoiceDate: "",
                            InvoiceAmount: "",
                        });
                        afterSave(res);
                    }
                    else {
                        window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                    }
                }).catch((err) => {
                    window.alert(err);
                }).finally(() => {
                    setLoading(false);
                });
            });
        }
    })
    const FileUploadRef = useRef();
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Project<code className='required-symbol'>*</code></Form.Label>
                            <DynamicDropdownSearchable
                                from={state.projectdetail.API + "/Project/GetAll"}
                                label="ProjectName"
                                value="Id"
                                fetchType="get"
                                name="ProjectId"
                                onDropdownChange={handleChange}
                                defaultval={inputValue.ProjectId} />
                        </Form.Group>
                    </Row>
                </Col>
                {inputValue.ProjectId &&
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>PO<code className='required-symbol'>*</code></Form.Label>
                                <DynamicDropdownSearchable
                                    from={state.projectdetail.API + "/POAdmin/GetByProject?Id=" + inputValue.ProjectId}
                                    addAttr={["POTo", "TotalAmount"]}
                                    label="PONo"
                                    value="Id"
                                    fetchType="get"
                                    name="fkPOId"
                                    onDropdownChange={handlePOChange}
                                    defaultval={inputValue.fkPOId} />
                            </Form.Group>
                        </Row>
                    </Col>
                }
            </Row>
            {inputValue.fkPOId &&
                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>PO To</Form.Label>
                                <div>{PODetail.POTo}</div>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>PO Amount</Form.Label>
                                <div><CurrencyLabel text={PODetail.TotalAmount} /></div>
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
            }
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Invoice Number<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="text" name="InvoiceNo" value={inputValue.InvoiceNo} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Invoice Number Format is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Invoice Date<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="date" name="InvoiceDate" value={inputValue.InvoiceDate} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Invoice date is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Invoice Amount<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control type="text" name="InvoiceAmount" value={inputValue.InvoiceAmount} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Invoice From is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Invoice Document<code className='required-symbol'>*</code></Form.Label>
                            <FileUpload folderpath="Invoices/" ext={["png", "jpg", "jpeg", "pdf"]} uploadedFiles={inputValue.InvoiceDocument} ref={FileUploadRef} />
                        </Form.Group>
                    </Row>
                </Col>

            </Row>
            <Row className="mb-3">
                <Col>
                    <ServerError error={serverError} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className='text-center'>
                    <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                        Cancel
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>&nbsp;&nbsp;
                    <Button variant="primary" type="submit" disabled={loading}>
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>

    )
}
