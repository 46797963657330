import CurrencyLabel from './components/controls/CurrencyLabel';
import { ApplicationAction } from './Reducer/ApplicationReducer';
import { store } from './Reducer/store'
export const getFileNamewithDate = (filename) => {
    let strFileName = "";
    let strFileExtension = "";
    strFileName = filename.substr(0, filename.lastIndexOf('.'));
    strFileExtension = filename.replace(/^.*\./, '').toLowerCase();
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
    let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
    let second = "" + now.getSeconds(); if (second.length == 1) { second = "0" + second; }
    let milliseconds = "" + now.getMilliseconds(); if (milliseconds.length == 1) { milliseconds = "0" + milliseconds; }
    let ttt = day + "" + month + "" + year + "" + hour + "" + minute + "" + second + "" + milliseconds;
    return strFileName + "-" + ttt + "." + strFileExtension;
}
export const validateFile = (file, allowedExt) => {
    const state = store.getState();
    if (typeof allowedExt == "string") {
        allowedExt = allowedExt.split(",");
    }
    if (file) {
        let ext = file.name.split(".").pop().toLowerCase();
        return (allowedExt.indexOf(ext) != -1 && file.size <= state.projectdetail.maxFileSize);
    }
    return false
}

export const dateDisplayFormater = (input) => {
    if (!input)
        return "";
    let date = new Date(input);
    let datee = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (datee < 10) {
        datee = "0" + datee;
    }
    if (month < 10) {
        month = "0" + month;
    }
    return <span className="text-nowrap">{datee + "-" + month + "-" + year}</span>;
}

export const getTraingMethod = (input) => {
    switch (input) {
        case "1":
        case 1:
            return "Classroom Training";
        case "2":
        case 2:
            return "Online Training";
        case "3":
        case 3:
            return "Offline Weekend";
        case "4":
        case 4:
            return "Online Weekend";
        default:
            return "";
    }
}
export const moduleAccess = (module) => {
    const state = store.getState();
    for (let loop = 0; loop < state.access.authorizedModules.length; loop++) {
        let mod = state.access.authorizedModules[loop];
        if (mod.tblSubMenu && mod.tblSubMenu.subMenuComponent) {
            if (mod.tblSubMenu.subMenuComponent.toLowerCase() == module.toLowerCase())
                return mod;
        }
    }
    return {};
}
export const getReportsModule = () => {
    const state = store.getState();
    let data = [];
    data = state.access.authorizedModules.filter(function (mod) {
        return mod.tblSubMenu.subMenuDescription == "Reports";
    })
    // for (let loop = 0; loop < state.access.authorizedModules.length; loop++) {
    //     let mod = state.access.authorizedModules[loop];
    //     if (mod.tblSubMenu && mod.tblSubMenu.subMenuComponent) {
    //         if (mod.tblSubMenu.subMenuComponent.toLowerCase() == module.toLowerCase())
    //             return mod;
    //     }
    // }
    return data;
}
export const getProjectDetail = (id, callback) => {
    const state = store.getState();
    fetch(state.projectdetail.API + "/project/get?id=" + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then(response => response.json()).then((res) => {
        if (res) {
            store.dispatch({ type: ApplicationAction.setApplication, payload: { ...res } });
        }
        if (typeof callback == "function") {
            callback.call(null, res);
        }
    }).finally(() => {
    });
}
export const getFileIcon = (ext) => {
    if (ext == "pdf") {
        return "FaRegFilePdf";
    }
    return "FaRegFileImage";
}
export const getFileSize = (size) => {
    return (size / (1024 * 1024)).toFixed(2) + " mb"
}
export const getExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
}
export const calculatePercentage = (amt, per) => {
    return parseFloat((per / 100) * amt);
}

export const currencyFormatter = (value) => {
    return <CurrencyLabel text={value} />
}
export const getDate = (date) => {
    if (date) {
        date = new Date(date);
        if (date) {
            let output = date.getDate();
            if (output < 9) {
                return "0" + output;
            }
            return output;
        }
    }
    return "";
}
export const getMonth = (date, onlyThreeChars) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    if (date) {
        date = new Date(date);
        if (date) {
            let month = months[date.getMonth()];
            if (onlyThreeChars) {
                return month.substring(0, 3);
            }
            return month;
        }
    }
    return "";
}

export const DataFilterType = {
    Equals: 0,
    NotEquals: 1,
    GreaterThan: 2,
    LessThan: 3,
    Contains: 4,
    NotContains: 5
};
export const convertDateToSet = (input) => {
    if (input && input.indexOf("T") > -1) {
        return input.split("T")[0];
    }
    return input;
}
export const SMSOrEmail = {
    SMS: "SMS",
    Email: "Email",
    Both: "Both",
}