import React, { createContext } from 'react';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.css'
// import './themes/light.scss'
// import './themes/common.scss'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import Prompt from './components/controls/Prompt';
// window.alert = function (message) {
//     ReactDOM.createElement(
//       <React.StrictMode>
//         <Prompt />
//       </React.StrictMode>,
//       document.getElementById('root')
//     );
// };
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();