import { memo } from "react";
import "./CurrencyLabel.css";
const CurrencyLabel = ({ text }) => {
    if (!text) {
        text = "0";
    }
    var x = text.toString().split(".")[0];
    let digits = "00";
    if (text.toString().split(".").length > 1) {
        digits = text.toString().split(".")[1];
    }
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = [otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree, digits];

    return (
        <span className="currency-label">{res.join(".")}</span>
    )
}

export default memo(CurrencyLabel)