import React from 'react';
import { memo } from 'react';

const NotFound = () => {
    return (
        <div>
            <h1>Page not found</h1>
        </div>
    );
}

export default memo(NotFound);
