import React from 'react';
import { Container, Row, Card, Col, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import Icon from '../../controls/Icons/Icon';
import home from "./logo-detail.png";
const Home = () => {
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const login = (() => {
        navigate("/login");
    });
    return (
        <Container className='home mt-3'>
            <Row className='d-flex min-vh-100 align-items-center'>                
                <Col md={12} className="text-center">
                    <div className="mb-3">
                        <img src={home} className="img-fluid home-img" />
                    </div>                    
                    <h1 className='mb-3'><span className='color-black'>BAB </span> <span className='color-blue'>Apps</span></h1>
                    <h3 className='mb-3 text-uppercase'><span className='color-blue'>Trying</span> is better than <span className='color-blue'>crying</span></h3>
                    {/*<h5 className='mb-4'>We constantly strive to deliver the best possible training and placement service to our students</h5>*/}
                    <div className='mb-4'>
                        <Button onClick={login}>Log in</Button>
                    </div>
                    {/*<h6 className='mb-2'><Icon name="AiOutlinePhone" className="phone" /> 044 42328182 </h6>*/}
                    {/*<h6 className='mb-4'><Icon name="AiOutlineMail" /> admin@accordedu.com</h6>*/}
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
