import { useState, useContext, useEffect, useRef } from "react"
import { Card, Button, Modal, CloseButton, Spinner, Row, Col, Form, Tab, Tabs } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Icon from "../../controls/Icons/Icon";
import { AccessAction } from '../../../Reducer/AccessReducer';
import "./UserSettings.css";
const UserSettings = () => {
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const setTheme = (e) => {
        let value = e.target.value;
        let obj = state.access;
        obj.user.theme = value;
        let inputValue = {
            theme: value,
            Id: obj.user.Id
        };
        setLoading(true);
        fetch(state.projectdetail.API + "/user/saveTheme", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(inputValue)
        }).then(response => response.json()).then((res) => {
            if (res.Succeeded) {
                dispatch({ type: AccessAction.setAccess, payload: { ...obj } });
            }
        }).catch((err) => {
            window.alert(err);
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <>
            <h4 className='form-heading mb-4'>
                Settings
            </h4>
            <Tabs
                defaultActiveKey="ColorPalette"
                id="uncontrolled-tab-example" activeKey={"ColorPalette"}
                className="mb-3"
            >
                <Tab eventKey="ColorPalette" value="ColorPalette" title="Color Palette">
                    <Card>
                        <Card.Body>
                            <div className='container p-3'>
                                <Row>
                                    <Col md={6}>
                                        <Row className="mb-4">
                                            <Col>
                                                <Form.Check className='theme-radio'
                                                    label={<div>
                                                        <div className='bg-turquoise p-4 d-inline-block'>

                                                        </div>
                                                        <div className='bg-turquoise-them2 p-4 d-inline-block'>

                                                        </div>
                                                        <div className='bg-turquoise-them3 p-4  d-inline-block'>

                                                        </div>
                                                        <div className=''>
                                                            <label>Turquoise (Default)</label>
                                                        </div>
                                                    </div>}
                                                    checked={"turquoise" == (state.access.user.theme || "turquoise")}
                                                    type={"radio"}
                                                    name="theme"
                                                    value="turquoise"
                                                    id={`theme-turquoise`}
                                                    onChange={setTheme} title="honey"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <Form.Check className='theme-radio'
                                                    label={
                                                        <div>
                                                            <div className='bg-lavender p-4 d-inline-block'>

                                                            </div>
                                                            <div className='bg-lavender-them2 p-4 d-inline-block'>

                                                            </div>
                                                            <div className='bg-lavender-them3 p-4  d-inline-block'>

                                                            </div>
                                                            <div className=''>
                                                                <label>Lavender</label>
                                                            </div>
                                                        </div>}
                                                    checked={"lavender" == (state.access.user.theme || "lavender")}
                                                    type={"radio"}
                                                    name="theme"
                                                    value="lavender"
                                                    id={`theme-lavender`}
                                                    onChange={setTheme} title="honey"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <Form.Check className='theme-radio'
                                                    label={<div>
                                                        <div className='bg-electric p-4 d-inline-block'>

                                                        </div>
                                                        <div className='bg-electric-them2 p-4 d-inline-block'>

                                                        </div>
                                                        <div className='bg-electric-them3 p-4  d-inline-block'>

                                                        </div>
                                                        <div className=''>
                                                            <label>Electric</label>
                                                        </div>
                                                    </div>}
                                                    checked={"electric" == (state.access.user.theme || "electric")}
                                                    type={"radio"}
                                                    name="theme"
                                                    value="electric"
                                                    id={`theme-electric`}
                                                    onChange={setTheme} title="electric"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row className="mb-4">
                                            <Col>
                                                <Form.Check className='theme-radio'
                                                    label={<div>
                                                        <div className='bg-honey p-4 d-inline-block'>

                                                        </div>
                                                        <div className='bg-honey-them2 p-4 d-inline-block'>

                                                        </div>
                                                        <div className='bg-honey-them3 p-4  d-inline-block'>

                                                        </div>
                                                        <div className=''>
                                                            <label>Honey</label>
                                                        </div>
                                                    </div>}
                                                    checked={"honey" == (state.access.user.theme || "honey")}
                                                    type={"radio"}
                                                    name="theme"
                                                    value="honey"
                                                    id={`theme-honey`}
                                                    onChange={setTheme} title="honey"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <Form.Check className='theme-radio'
                                                    label={<div>
                                                        <div className='bg-amber p-4 d-inline-block'>

                                                        </div>
                                                        <div className='bg-amber-them2 p-4 d-inline-block'>

                                                        </div>
                                                        <div className='bg-amber-them3 p-4  d-inline-block'>

                                                        </div>
                                                        <div className=''>
                                                            <label>Amber</label>
                                                        </div>
                                                    </div>}
                                                    checked={"amber" == (state.access.user.theme || "amber")}
                                                    type={"radio"}
                                                    name="theme"
                                                    value="amber"
                                                    id={`theme-amber`}
                                                    onChange={setTheme} title="Amber"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <Form.Check className='theme-radio'
                                                    label={
                                                        <div>
                                                            <div className='bg-lime p-4 d-inline-block'>

                                                            </div>
                                                            <div className='bg-lime-them2 p-4 d-inline-block'>

                                                            </div>
                                                            <div className='bg-lime-them3 p-4  d-inline-block'>

                                                            </div>
                                                            <div className=''>
                                                                <label>Lime</label>
                                                            </div>
                                                        </div>}
                                                    checked={"lime" == (state.access.user.theme || "lime")}
                                                    type={"radio"}
                                                    name="theme"
                                                    value="lime"
                                                    id={`theme-lime`}
                                                    onChange={setTheme} title="honey"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='col-12 mb-3'>
                                        {loading ?
                                            <div><h5 className="d-inline-block">Please wait while we change the theme for you...</h5> <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                                            </div> : ""}
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Tab>
            </Tabs>
        </>
    );
}

export default UserSettings;
