import { createStore, combineReducers, applyMiddleware } from "redux";
// import UIReducer from "./UIReducer";
import AccessReducer from "./AccessReducer";
import ApplicationReducer from "./ApplicationReducer";
import POReducer from "./POReducer";
const isDev = () => {
    return window.location.href.indexOf("localhost") > -1;
}
const productionModeReducer = () => {
    return isDev()
}

const projectdetail = {
    "API": (isDev() ? "https://localhost:7081/api" : "/api"),
    // "ProjectName": <img src="../logo.png" className="img-fluid logo" />,
    // "ProjectLogo": <img src="../logo.png" className="img-fluid logo" />,
    "ProjectName": "BAB Apps",
    "ProjectLogo": <img src="../logo.png" className="img-fluid logo" />,
    "PoweredBy": "Bow and Baan",
    "PoweredByLink": "https://www.bowandbaan.com/",
    "ErrorMessage": "Something went wrong",
    "maxFileSize": 1048576,
};

const projectReducer = (detail = projectdetail, actions) => {
    return detail;
}

const rootReducer = combineReducers(
    {       
        access: AccessReducer,
        project: ApplicationReducer,
        po: POReducer,
        projectdetail: projectReducer,
        //ui: UIReducer,
        IsDevMode: productionModeReducer,        
    }
);
// export const store = createStore(rootReducer, applyMiddleware(applyMiddleware, thunk));
export const store = createStore(rootReducer);

// store.dispatch({ type: AccessAction.setAccess, payload: { menuName: "Thamu" } });
// store.dispatch({ type: "hi", payload: { API: "123" } });
// console.log("aaa", store.getState());
// store.subscribe(() => {
//     console.log("aaa", store.getState());
// });
