import React, { useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const ResetPasswordForm = ({ mobile, heading, afterReset, showCancel, onCancel }) => {
  const state = useSelector(state => state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState({
    mobile: mobile,
    password: "",
    confirmpassword: "",
  });
  useEffect(() => {
    setInputValue({
      mobile: mobile,
      password: "",
      confirmpassword: "",
    })
  }, []);
  const handleChange = ((e) => {
    const name = e.target.name;
    let value = e.target.value;
    let maxLength = e.target.maxLength;
    if (e.target.type == "checkbox") {
      value = e.target.checked;
    }
    else {
      if (maxLength && maxLength > 0 && value.length > maxLength) {
        return;
      }
    }
    setInputValue(c => ({ ...c, [name]: value }));
  });
  const handleOTP = ((e) => {

    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false || !pwdmatching || pwdweek) {
      setValidated(true);
      return;
    }
    setErrorMessage("");
    setShowErrorMessage(false);
    let data = JSON.parse(JSON.stringify(inputValue));
    data.mobile = mobile;
    setLoading(true);
    fetch(state.projectdetail.API + "/auth/changePassword", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(res => res.json()).then((res) => {
      setLoading(false);
        if (res.Succeeded) {
        afterReset(res);
      }
      else {
        setErrorMessage(res.Message);
        setShowErrorMessage(true);
      }

    }).catch((err) => {
      setLoading(false);
      setErrorMessage(state.projectdetail.errorMessage);
      setShowErrorMessage(true);
    });
  })
  const onPwdKeyUp = () => {
    checkPasswords();
    checkWeekPassword();
  }
  const [pwdmatching, setPwdMatching] = useState(true);
  const checkPasswords = () => {
    setPwdMatching(inputValue.password == inputValue.confirmpassword);
  }
  const [pwdweek, setPwdweek] = useState(false);
  const checkWeekPassword = () => {
    let reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,16}$/
    let status = !reg.test(inputValue.password);
    setPwdweek(status);
  }
  return (
    <Form noValidate validated={validated} onSubmit={handleOTP}>
      <h2 className='heading text-center mb-2'>{heading}</h2>
      <Row className="mb-3">
        <Col>
          <Alert show={showErrorMessage} variant="danger" onClose={() => setShowErrorMessage(false)} dismissible>
            {errorMessage}
          </Alert>
        </Col>
      </Row>
      <Row className="mb-4">
        <Form.Group>
          <Form.Label>Create Password<code className='required-symbol'>*</code></Form.Label>
          <Form.Control onKeyUp={onPwdKeyUp} required type="password" name="password" value={inputValue.password} onChange={handleChange} autoComplete="off" />
          <Form.Control.Feedback type="invalid">Enter your Password</Form.Control.Feedback>
          <div className={(pwdweek ? "" : "d-none") + " invalid-msg"}>
            <span>Password is weak. It should contain alphabets, numbers and symbols</span>
          </div>
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Form.Group>
          <Form.Label>Confirm Password<code className='required-symbol'>*</code></Form.Label>
          <Form.Control onKeyUp={checkPasswords} required type="password" name="confirmpassword" value={inputValue.confirmpassword} onChange={handleChange} autoComplete="off" />
          <Form.Control.Feedback type="invalid">Re-enter your Password</Form.Control.Feedback>
          <div className={(!pwdmatching ? "" : "d-none") + " invalid-msg"}>
            <span>Passwords do not match</span>
          </div>
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Col className={showCancel ? "text-center" : ""}>
          {showCancel ?
            <>
              <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                Cancel
                {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
              </Button>&nbsp;&nbsp;</> : ""
          }
          <Button className={!showCancel ? 'w-100' : ""} variant="primary" type="submit" disabled={loading}>
            Submit
            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(ResetPasswordForm);
