import { useState, useContext, useEffect, useRef } from "react"
import { Card, Button, Modal, CloseButton, Spinner, Row, Col } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import Icon from "../../controls/Icons/Icon";
import SMSEmailLog from "./SMSEmailLog";
import SMSEmailSummaryProjectWise from "./SMSEmailSummaryProjectWise";
import SMSEmailLogModal from "./SMSEmailLogModal";
import { DataFilterType } from "../../../Utils";

const SMSEmail = () => {
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState({});
    useEffect(() => {
        fetch(state.projectdetail.API + "/SMSEmailAdmin/GetOverallSummary", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => response.json()).then((res) => {
            if (res.Succeeded) {
                setSummary(res.Data);
            }
            else {
                window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }
        }).catch((err) => {
            window.alert(err);
        }).finally(() => {
            setLoading(false);
        });
    }, []);


    const [logFilter, setLogFilter] = useState([]);
    const openLogAllEmails = (filter) => {
        setLogFilter(
            [
                { Field: "EmailFlag", FilterType: DataFilterType.Equals, Value: true }
            ]
        );
        setShowLog(true);
    };
    const openLogFailedEmails = (filter) => {
        setLogFilter(
            [
                { Field: "EmailFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "EmailStatus", FilterType: DataFilterType.Equals, Value: false }
            ]);
        setShowLog(true);
    };
    const openLogSuccessEmails = (filter) => {
        setLogFilter(
            [
                { Field: "EmailFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "EmailStatus", FilterType: DataFilterType.Equals, Value: true }
            ]);
        setLogFilter({ EmailFlag: true, EmailStatus: true });
        setShowLog(true);
    };

    const openLogAllSMS = (filter) => {
        setLogFilter(
            [
                { Field: "SMSFlag", FilterType: DataFilterType.Equals, Value: true }
            ]
        );
        setShowLog(true);
    };
    const openLogFailedSMS = (filter) => {
        setLogFilter(
            [
                { Field: "SMSFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "SMSStatus", FilterType: DataFilterType.Equals, Value: false }
            ]);
        setShowLog(true);
    };
    const openLogSuccessSMS = (filter) => {
        setLogFilter(
            [
                { Field: "SMSFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "SMSStatus", FilterType: DataFilterType.Equals, Value: true }
            ]);
        setLogFilter({ SMSFlag: true, SMSStatus: true });
        setShowLog(true);
    };

    const [showLog, setShowLog] = useState(false);

    return (
        <>
            <h4 className='form-heading mb-4'>
                SMS Email Summary
            </h4>
            <Row className="mb-3">
                <Col md="6">
                    <Row>
                        <Col md="4">
                            <Card>
                                <Card.Body className="p-2">
                                    <h6>Total Emails</h6>
                                    <h3> <a onClick={openLogAllEmails}>{summary.TotalEmail}</a></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body className="p-2">
                                    <h6>Success Emails</h6>
                                    <h3> <a onClick={openLogSuccessEmails}>{summary.TotalEmailSuccess}</a></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body className="p-2">
                                    <h6>Failed Emails</h6>
                                    <h3> <a onClick={openLogFailedEmails}>{summary.TotalEmailFailed}</a></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md="6">
                    <Row>
                        <Col md="4">
                            <Card>
                                <Card.Body className="p-2">
                                    <h6>Total SMS</h6>
                                    <h3><a onClick={openLogAllSMS}>{summary.TotalSMS}</a></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body className="p-2">
                                    <h6>Success SMS</h6>
                                    <h3><a onClick={openLogSuccessSMS}>{summary.TotalSMSSuccess}</a></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body className="p-2">
                                    <h6>Failed SMS</h6>
                                    <h3><a onClick={openLogFailedSMS}>{summary.TotalSMSFailed}</a></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="6">
                    <Row>
                        <Col md="4">
                            <Card>
                                <Card.Body className="p-2">
                                    <h6>Email Credits Used</h6>
                                    <h3> <a>{summary.TotalEmailCredits}</a></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body className="p-2">
                                    <h6>SMS Credits Used</h6>
                                    <h3> <a>{summary.TotalSMSCredits}</a></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Card className="mb-3">
                <Card.Body>
                    <SMSEmailSummaryProjectWise />
                </Card.Body>
            </Card>
            <SMSEmailLogModal show={showLog} filter={logFilter} />
        </>
    );
}

export default SMSEmail;
