import React, { useState, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams} from 'react-router-dom';
import { AccessAction } from '../Reducer/AccessReducer';
import ErrorHandler from './controls/ErrorHandler';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import NotFound from './controls/NotFound';
import AccessDenied from './controls/AccessDenied';
import Loading from './controls/Loading';
import SliderLayout from './SliderLayout';
import PageSkeleton from './skeleton/PageSkeleton';

function Layout(props) {
    const loc = useLocation();
    const [error, setError] = useState();
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [outlet, setOutlet] = useState("");
    const { fetch: originalFetch } = window;
    const urlParams = useParams();
    window.fetch = async (...args) => {
        let [resource, config] = args;
        if (!config) {
            config = {};
        }
        config.credentials = "include";
        let loggedUser = localStorage.getItem("user");
        if (loggedUser) {
            let objLoggedUser = JSON.parse(loggedUser);
            if (config.headers && Object.keys(config.headers).length > 0) {
                config.headers["Authorization"] = "Bearer " + objLoggedUser.AccessToken;
            }
            else {
                config.headers = { "Authorization": "Bearer " + objLoggedUser.AccessToken };
            }
        }
        let response = await originalFetch(resource, config);
        if (response.status === 403) {
            localStorage.clear();
            navigate("/");
        }
        else if (response.status == 401) {
            let tokenRefreshing = localStorage.getItem("refreshToken");
            if (tokenRefreshing != "1") {
                try {
                    localStorage.setItem("refreshToken", "1");
                    await fetch(state.projectdetail.API + "/auth/refresh", { method: "POST", async: false, });
                    localStorage.setItem("refreshToken", "0");
                }
                catch (error) {
                    localStorage.clear();
                    navigate("/");
                }
            }
            response = await originalFetch(resource, config);
        }
        else if (response.status == 500) {
            const error = await response.json();
            if (error && error.message) {
                window.alert(error.message);
            }
            else {
                window.alert(state.projectdetail.ErrorMessage);
            }
        }
        else {
            localStorage.setItem("refreshToken", "0");
            return response;
        }
    };

    useEffect(() => {
        var location = loc.pathname;
        if (urlParams && Object.keys(urlParams).length > 0) {
            for (let urlParamKey in urlParams) {
                location = location.replace("/" + urlParams[urlParamKey], "/:" + urlParamKey);
            }
        }
        if (state.access.authorizedMenus) {
            let getCurrentMenu = {};
            for (let loop = 0; loop < state.access.authorizedMenus.length; loop++) {
                let m = state.access.authorizedMenus[loop];
                if (m.tblMenu.path && location) {
                    if (m.tblMenu.path.toLowerCase() == location.toLowerCase()) {
                        getCurrentMenu = { ...m };
                        break;
                    }
                }
            }
            if (Object.keys(getCurrentMenu).length == 0) {
                setOutlet(<NotFound menu={loc.pathname} />);
            }
            else if (!getCurrentMenu.view) {
                setOutlet(<AccessDenied menu={loc.pathname} options={getCurrentMenu} />);
            }
            else {
                getCurrentMenu.authorizedMenus = state.access.authorizedMenus;
                getCurrentMenu.authorizedModules = state.access.authorizedModules;
                dispatch({ type: AccessAction.setAccess, payload: { ...getCurrentMenu } });
                setOutlet(<Outlet />);
            }
        }
        else {
            setOutlet(<PageSkeleton />)

            fetch(state.projectdetail.API + "/roleaccess/currentaccess?path=" + location, { credentials: "include" }).then(res => res.json()).then((res) => {
                if (res && res.hasOwnProperty("menuAccess") && res.menuAccess) {
                    dispatch({ type: AccessAction.setAccess, payload: { ...res.menuAccess, "authorizedMenus": res.authorizedMenus, "user": res.user } });
                    if (res.menuAccess.authorizedMenu) {
                        if (res.menuAccess.sessionExpired) {
                            localStorage.clear();
                            state.access.showMenu = false;
                            navigate("/");
                        }
                        else {
                            if (!res.menuAccess.view) {
                                setOutlet(<AccessDenied />)
                            }
                            else {
                                setOutlet(<Outlet />);
                            }
                        }
                    }
                    else {
                        setOutlet(<Outlet />);
                    }
                }
                else {
                    setOutlet(<NotFound menu={loc.pathname} />)
                }
            }).catch((err) => { setOutlet(<ErrorHandler error={err} />) });
        }
    }, [loc.pathname]);
    // useEffect(() => {
    //     var location = loc.pathname;
    //     let loggedinUser = localStorage.getItem("user");
    //     if (loggedinUser && location == "/") {
    //         navigate("/admin/dashboard");
    //         return;
    //     }
    //     if (location == "/")
    //         location = "/login";
    //     setOutlet(<PageSkeleton />)
    //     fetch(state.projectdetail.API + "/roleaccess/currentaccess?path=" + location).then(res => res.json()).then((res) => {
    //         if (Array.isArray(res) && res.length > 0) {
    //             dispatch({ type: AccessAction.setAccess, payload: res[0] });
    //             if (res[0].authorizedMenu) {
    //                 if (res[0].sessionExpired) {
    //                     localStorage.clear();
    //                     state.access.showMenu = false;
    //                     navigate("/");
    //                 }
    //                 else {
    //                     if (!res[0].view) {
    //                         setOutlet(<AccessDenied />)
    //                     }
    //                     else {
    //                         setOutlet(<Outlet />);
    //                     }
    //                 }
    //             }
    //             else {
    //                 setOutlet(<Outlet />);
    //             }
    //         }
    //         else {
    //             setOutlet(<NotFound menu={loc.pathname} />)
    //         }
    //     }).catch((err) => { setOutlet(<ErrorHandler error={err} />) });
    // }, [loc.pathname]);

    useEffect(() => {
        if (state && state.access && state.access.user && state.access.user.theme)
            document.body.setAttribute("data-theme", state.access.user.theme)
        else {
            document.body.setAttribute("data-theme", "turquoise")
        }
    }, [state])
    if (state.access.authorizedMenu) {
        if (state.access.sessionExpired) {
            return <></>
        }
        return (
            <div>
                <SliderLayout outlet={outlet} />
            </div>
        )
    }
    else {
        return (
            <div>
                {outlet}
            </div>
        )
    }
}

export default memo(Layout);