import { useState, useContext, useEffect, useRef } from "react"
import { Card, Button, Modal, CloseButton, Spinner } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useDispatch, useSelector } from 'react-redux';
import ProjectForm from './ProjectForm';
import Icon from "../../controls/Icons/Icon";
import { useNavigate } from "react-router-dom";
import { ApplicationAction } from "../../../Reducer/ApplicationReducer";

const Project = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState({
    });
    const columns = [
        {
            field: "ProjectName",
            title: "Project Name",
            filterable:true,
            sortable: true,
        },
        {
            field: "URL",
            title: "URL",
            filterable: true,
            sortable: true,
        },
        {
            field: "APPID",
            title: "APPID",
            filterable: true,
            sortable: true,
        },
        {
            field: "APIKey",
            title: "APIKey",
            filterable: true,
            sortable: true,
        },
        {
            dataField: "remove",
            title: "",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (<div>
                    {state.access.edit ? <Button
                        className="btn-xs me-2 mb-2 p-0" variant="link"
                        onClick={() => handleEdit(row)}
                    ><Icon name="BsPencilFill" className='fs-5' /></Button> : ""}

                    {state.access.delete ? <Button variant="link"
                        className="btn-xs mb-2 p-0"
                        onClick={() => handleDelete(row)}
                    >
                        <Icon name="BsTrash" className='fs-5' />
                    </Button> : ""}
                </div>
                );
            },
        }
    ];

    const handleEdit = (res) => {
        setInputValue(res)
        setShowModal(true)
    };

    const handleDelete = ((res) => {
        window.confirm("Do you want to delete the project?", function () {
            setLoading(true);
            let obj = {};
            obj.Id = res.Id;
            fetch(state.projectdetail.API + "/project/delete?Id=" + res.Id, {   
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                {
                    if (res.Succeeded) {
                        window.alert("Deleted successfuly");
                        setRefreshGrid(!refreshGrid);
                    }
                    else {
                        window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                    }
                }
            }).catch((err) => {
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        })

    });

    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "ProjectName",
        sortOrder: "asc",
        filter: true,
        //onDoubleClickRow: function (row) {            
        //    dispatch({ type: ApplicationAction.setApplication, payload: row });
        //    navigate("/admin/project/" + row.Id + "/dashboard");
        //},
    };
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleAdd = () => {
        setInputValue([])
        setShowModal(true)
    };
    const handleAfter = (res) => {
        if (res.Succeeded) {
            window.alert("Project saved successfully");
            handleClose();
            setRefreshGrid(!refreshGrid);
        }
        else {
            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
    };
    return (
        <>
            <h4 className='form-heading mb-4'>
                Project
                <div className='d-inline-block float-end'>
                    <Button onClick={handleAdd}>Add <Icon name="BsPersonPlus" /></Button>&nbsp;
                </div>
            </h4>
            <Card>
                <Card.Body>
                    {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                    <DataGrid url={state.projectdetail.API + "/project/grid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header>
                    <h4>{inputValue.Id ? "Edit " : "Add "} Project</h4>
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <ProjectForm afterSave={handleAfter} _inputValue={inputValue} showPassword={true} onCancel={handleClose} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Project;
