(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("peekdata-datagateway-api-sdk"), require("@fortawesome/free-solid-svg-icons"), require("classnames"), require("react-redux"), require("@fortawesome/react-fontawesome"), require("moment"), require("react-sortable-hoc"), require("react-bootstrap"), require("redux"), require("react-chartjs-2"), require("react-responsive-modal"), require("@kunukn/react-collapse"), require("react-datepicker"), require("react-select"), require("react-table"), require("react-copy-to-clipboard"), require("react-syntax-highlighter"), require("redux-saga"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "peekdata-datagateway-api-sdk", "@fortawesome/free-solid-svg-icons", "classnames", "react-redux", "@fortawesome/react-fontawesome", "moment", "react-sortable-hoc", "react-bootstrap", "redux", "react-chartjs-2", "react-responsive-modal", "@kunukn/react-collapse", "react-datepicker", "react-select", "react-table", "react-copy-to-clipboard", "react-syntax-highlighter", "redux-saga"], factory);
	else if(typeof exports === 'object')
		exports["ReportBuilder"] = factory(require("react"), require("peekdata-datagateway-api-sdk"), require("@fortawesome/free-solid-svg-icons"), require("classnames"), require("react-redux"), require("@fortawesome/react-fontawesome"), require("moment"), require("react-sortable-hoc"), require("react-bootstrap"), require("redux"), require("react-chartjs-2"), require("react-responsive-modal"), require("@kunukn/react-collapse"), require("react-datepicker"), require("react-select"), require("react-table"), require("react-copy-to-clipboard"), require("react-syntax-highlighter"), require("redux-saga"));
	else
		root["ReportBuilder"] = factory(root["react"], root["peekdata-datagateway-api-sdk"], root["@fortawesome/free-solid-svg-icons"], root["classnames"], root["react-redux"], root["@fortawesome/react-fontawesome"], root["moment"], root["react-sortable-hoc"], root["react-bootstrap"], root["redux"], root["react-chartjs-2"], root["react-responsive-modal"], root["@kunukn/react-collapse"], root["react-datepicker"], root["react-select"], root["react-table"], root["react-copy-to-clipboard"], root["react-syntax-highlighter"], root["redux-saga"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__37__, __WEBPACK_EXTERNAL_MODULE__38__, __WEBPACK_EXTERNAL_MODULE__40__, __WEBPACK_EXTERNAL_MODULE__42__, __WEBPACK_EXTERNAL_MODULE__43__, __WEBPACK_EXTERNAL_MODULE__44__, __WEBPACK_EXTERNAL_MODULE__46__) {
return 