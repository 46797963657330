import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import ServerError from '../../controls/ServerError';
import Icon from '../../controls/Icons/Icon';
import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from 'draft-js';
import "/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

export default function SMSEmailTemplateForm({ _inputValue, afterSave, onCancel }) {
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        Id: 0,
        smsemailtemplate: "",
        active: 1,
        emailContentDraft: EditorState.createEmpty()
    });
    const [serverError, setServerError] = useState({});
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);

    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            let data = JSON.parse(JSON.stringify(inputValue));
            delete data.emailContentDraft;
            setLoading(true);
            fetch(state.projectdetail.API + "/smsemailtemplate/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    setInputValue({
                        smsemailtemplate: ""
                    });
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    })

    const onEmailContentChange = (data) => {
        const emailContent = draftToHtml(convertToRaw(data.getCurrentContent()));
        setInputValue(c => ({ ...c, ["emailContent"]: emailContent, emailContentDraft: data }));
    }
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={12} className="mb-3">
                    <Row>
                        <Form.Group>
                            <Form.Label>SMS Email Template<code className='required-symbol'>*</code></Form.Label>
                            <DynamicDropdownSearchable from={state.projectdetail.API + "/smsemailtemplate/getType"} label="SMSEmailTemplateType" value="Id" fetchType="get" name="fkSMSEmailTemplateTypeId" onDropdownChange={handleChange} defaultval={inputValue.fkSMSEmailTemplateTypeId} />
                            <Form.Control.Feedback type="invalid">SMS Email Template Type is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={12} className="mb-3">
                    <Row>
                        <Form.Group>
                            <Form.Label>SMS Content<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control as="textarea" type="text" name="smsContent" value={inputValue.smsContent} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">SMS Content is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={12} className="mb-3">
                    <Row>
                        <Form.Group>
                            <Form.Label>Email Subject<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control type="text" name="emailSubject" value={inputValue.emailSubject} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Email Subject is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={12} className="mb-3">
                    <Row>
                        <Form.Group>
                            <Form.Label>Email Content<code className='required-symbol'>*</code></Form.Label>
                            <Editor
                                editorState={inputValue.emailContentDraft}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEmailContentChange}
                            />

                            {/* <Form.Control as="textarea" type="text" name="emailContent" value={inputValue.emailContent} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">SMS Content is required</Form.Control.Feedback> */}
                        </Form.Group>
                    </Row>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <ServerError error={serverError} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className='text-center'>
                    <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                        Cancel
                    </Button>&nbsp;&nbsp;
                    <Button variant="primary" type="submit" disabled={loading}>
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}
