import React,{useEffect} from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const FPResendOTPForm = ({ mobile,showRP }) => {
  const state = useSelector(state => state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState({   
    mobile: mobile,
    otp: ""
  });
  useEffect(() => {
    setInputValue({   
      mobile: mobile,
      otp: ""
    })    
  }, []);
  const handleChange = ((e) => {
    const name = e.target.name;
    let value = e.target.value;
    let maxLength = e.target.maxLength;
    if (e.target.type == "checkbox") {
        value = e.target.checked;
    }
    else {
      if (maxLength && maxLength > 0 && value.length > maxLength) {
            return;
        }
    }
    setInputValue(c => ({ ...c, [name]: value }));
});
  const handleOTP = ((e) => {
    
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setErrorMessage("");
    setShowErrorMessage(false);
    
    let data = JSON.parse(JSON.stringify(inputValue));
    data.mobile = mobile;
    fetch(state.projectdetail.API + "/auth/checkOTP", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(res => res.json()).then((res) => {
        if (res.Succeeded) {
        
        showRP();
      }
      else {
        setErrorMessage(res.Message);
        setShowErrorMessage(true);
      }

    }).catch((err) => {
      setErrorMessage(state.projectdetail.errorMessage);
      setShowErrorMessage(true);
    });
  })

  const handleResendOTP = ((e) => {   
    
    setErrorMessage("");
    setShowErrorMessage(false);
    
    let data = JSON.parse(JSON.stringify(inputValue));
    data.mobile = mobile;
    fetch(state.projectdetail.API + "/auth/resendOTP", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(res => res.json()).then((res) => {debugger;
        if (res.Succeeded) {        

        setErrorMessage('OTP resent sucessfully!');
        setShowErrorMessage(true);
      }
      else {
        setErrorMessage(res.Message);
        setShowErrorMessage(true);
      }

    }).catch((err) => {
      setErrorMessage(state.projectdetail.errorMessage);
      setShowErrorMessage(true);
    });
  })

  return (
    <Form noValidate validated={validated} onSubmit={handleOTP}>      
      <h2 className='heading text-center mb-2'>Forgot Password</h2>
      <Row className="mb-3">
        <Col>
          <Alert show={showErrorMessage} variant="danger" onClose={() => setShowErrorMessage(false)} dismissible>
            {errorMessage}
          </Alert>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <label>Mobile Number {mobile}</label>
        </Col>
      </Row>
      <Row className="mb-4">
        <Form.Group>
          <Form.Control required onWheel={(e) => e.target.blur()} type="number" placeholder='Enter your OTP' name="otp" value={inputValue.otp} onChange={handleChange} autoComplete="off" />
          <Form.Control.Feedback type="invalid">Enter your OTP</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Col>
          <Button className='w-100' variant="primary" type="submit" disabled={loading}>
            Submit
            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className='text-center'>
            <a className='link' onClick={handleResendOTP} >Resend OTP</a>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(FPResendOTPForm);
