import React, { useState, useEffect } from 'react';
import { Toast, Row, Col, Modal, Button, Form } from 'react-bootstrap'
import Icon from '../Icons/Icon';
import "./ConfirmPrompt.css";
const ConfirmPrompt = ({ options }) => {
    if (!options.YesButtonText) {
        options.YesButtonText = "Yes";
    }
    if (!options.NoButtonText) {
        options.NoButtonText = "No";
    }
    const handleOk = () => {
        window.hideConfirm();
        if (typeof options.onYes == "function") {
            options.onYes();
        }
    }
    const handleNo = () => {
        window.hideConfirm();
        if (typeof options.onNo == "function") {
            options.onNo();
        }
    }
    const [confirmAction, setConfirmAction] = useState(false);
    const onConfirmAction = (e) => {
        setConfirmAction(e.target.checked);
    }
    return (
        <Modal show={options.show} className="confirm-message" centered>
            <Modal.Body>
                <Row className='mb-3'>
                    <Col className='confirm-message-icon'>
                        <Icon name="BsFillEmojiFrownFill" />
                        {options.icon}
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col className='confirm-message-message'>
                        {options.message}
                    </Col>
                </Row>
                {options.confirmCheckbox ?
                    <Row className='mb-4'>
                        <Col className='confirm-message-confimation'>
                            <Form.Group>
                                <Form.Label></Form.Label>
                                <Form.Check type="checkbox" checked={confirmAction} onChange={onConfirmAction} id="confirmAction" label={options.confirmCheckboxText} />
                            </Form.Group>
                        </Col>
                    </Row> : ""
                }
                <Row className='mb-3'>
                    <Col className='confirm-message-buttons'>
                        <Button onClick={handleNo} variant="outline-primary">{options.NoButtonText}</Button>&nbsp;&nbsp;
                        {options.confirmCheckbox ? <Button disabled={!confirmAction} onClick={handleOk}>{options.YesButtonText}</Button> :
                            <Button onClick={handleOk}>{options.YesButtonText}</Button>}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
export default ConfirmPrompt;
