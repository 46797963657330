import { useState, useContext, useEffect, useRef } from "react"
import { Card, Button, Modal, CloseButton, Spinner, Row, Col } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import Icon from "../../controls/Icons/Icon";
import CurrencyLabel from "../../controls/CurrencyLabel";

const POSummary = () => {
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState({});
    useEffect(() => {
        fetch(state.projectdetail.API + "/POAdmin/GetOverallSummary", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => response.json()).then((res) => {
            if (res.Succeeded) {
                debugger
                setSummary(res.Data);
            }
            else {
                window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
            }
        }).catch((err) => {
            window.alert(err);
        }).finally(() => {
            setLoading(false);
        });
    }, []);
    const columns = [
        {
            field: "ProjectName",
            title: "Project",
            sortable: true,
        },
        {
            field: "TotalPO",
            title: "Total PO",
            sortable: true,
        },
        {
            field: "TotalAmount",
            title: "Total Amount",
            sortable: true,
            formatter: function (value) {
                return <CurrencyLabel text={value} />
            }
        },
        {
            field: "TotalTax",
            title: "Total Tax",
            sortable: true,
            formatter: function (value) {
                return <CurrencyLabel text={value} />
            }
        },
    ];

    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "ProjectName",
        sortOrder: "asc",
    };



    return (
        <>
            <h4 className='form-heading mb-4'>
                PO Summary
            </h4>
            <Row className="mb-3">
                <Col md="12">
                    <Row>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <h6>Total PO</h6>
                                    <h3>{summary.TotalPO}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <h6>Total PO Amount</h6>
                                    <h3><CurrencyLabel text={summary.TotalAmount}/></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <h6>PO Tax Amount</h6>
                                    <h3><CurrencyLabel text={summary.TotalTax} /></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>                
            </Row>
            <Card>
                <Card.Body>
                    {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                    <DataGrid url={state.projectdetail.API + "/POAdmin/ProjectWiseSummaryGrid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
        </>
    );
}

export default POSummary;
