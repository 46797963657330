import React, { useEffect, useRef, useState } from 'react'
import { Form, Row, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import CurrencyLabel from '../../controls/CurrencyLabel';
import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
import FileUpload from '../../controls/FileUpload/FileUpload';
import Icon from '../../controls/Icons/Icon';
import ServerError from '../../controls/ServerError';
export default function PaymentForm({ _inputValue, afterSave, onCancel }) {
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        ProjectId: "",
        fkInvoiceId: 0,
        Id: 0,
        Status: true,
        Amount: 0,
        Tax: 0,
        PaymentMode: "1",
        PaymentDate: "",
        PaymentTransactionId: "",
        PaymentRemarks: "",
    });
    const [InvoiceDetail, setInvoiceDetail] = useState({
        InvoiceNo: "",
        InvoiceDate: "",
        InvoiceAmount: "",
    });
    const [serverError, setServerError] = useState({});
    const handlePOChange = ((event) => {
        const selectedOption = event.target.selectedOptions[0];
        const selectedInvoiceDate = selectedOption.getAttribute("data-InvoiceDate");
        const selectedInvoiceAmount = selectedOption.getAttribute("data-InvoiceAmount");
        const selectedInvoiceNo = selectedOption.getAttribute("data-InvoiceNo");
        setInvoiceDetail(c => ({
            ...c,
            ["InvoiceNo"]: selectedInvoiceNo,
            ["InvoiceDate"]: selectedInvoiceDate,
            ["InvoiceAmount"]: selectedInvoiceAmount
        }));
        setInputValue(c => ({ ...c, ["Amount"]: selectedInvoiceAmount }));
        handleChange(event);
    });
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue(c => ({ ...c, ["fkInvoiceId"]: _inputValue.Id }));
    }, []);

    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            let data = JSON.parse(JSON.stringify(inputValue));            

            fetch(state.projectdetail.API + "/PaymentAdmin/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    setInputValue({
                        ProjectId: "",
                        fkInvoiceId: "",
                        Id: 0,
                        PaymentNo: "",
                        PaymentDate: "",
                        PaymentAmount: "",
                    });
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    })
    const FileUploadRef = useRef();
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Project<code className='required-symbol'>*</code></Form.Label>
                            <DynamicDropdownSearchable
                                from={state.projectdetail.API + "/Project/GetAll"}
                                label="ProjectName"
                                value="Id"
                                fetchType="get"
                                name="ProjectId"
                                onDropdownChange={handleChange}
                                defaultval={inputValue.ProjectId} />
                        </Form.Group>
                    </Row>
                </Col>
                {inputValue.ProjectId &&
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Invoice<code className='required-symbol'>*</code></Form.Label>
                                <DynamicDropdownSearchable
                                    from={state.projectdetail.API + "/InvoiceAdmin/GetByProject?Id=" + inputValue.ProjectId}
                                    addAttr={["InvoiceDate", "InvoiceAmount"]}
                                    label="InvoiceNo"
                                    value="Id"
                                    fetchType="get"
                                    name="fkInvoiceId"
                                    onDropdownChange={handlePOChange}
                                    defaultval={inputValue.fkInvoiceId} />
                            </Form.Group>
                        </Row>
                    </Col>
                }
            </Row>
            {inputValue.fkInvoiceId &&
                <Row className="mb-3">
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Invoice Date</Form.Label>
                                <div>{InvoiceDetail.InvoiceDate}</div>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Form.Group>
                                <Form.Label>Amount Payable</Form.Label>
                                <div><CurrencyLabel text={InvoiceDetail.InvoiceAmount} /></div>
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
            }
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Payment Date<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="date" name="PaymentDate" value={inputValue.PaymentDate} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Payment date is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Amount<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="text" name="PaymentNo"
                                value={inputValue.Amount} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Payment Amount is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Payment Tax</Form.Label>
                            <Form.Control type="text" name="Tax" value={inputValue.Tax} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Select name="PaymentMode" required onChange={handleChange} value={inputValue.PaymentMode}>
                                <option value="">--Select--</option>
                                <option value="1">Online</option>
                                <option value="2">Cash</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Payment Transaction Id</Form.Label>
                            <Form.Control type="text" name="PaymentTransactionId" value={inputValue.PaymentTransactionId} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Payment Remarks</Form.Label>
                            <Form.Control type="text" name="PaymentRemarks" value={inputValue.PaymentRemarks} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>


            <Row className="mb-3">
                <Col>
                    <ServerError error={serverError} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className='text-center'>
                    <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                        Cancel
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>&nbsp;&nbsp;
                    <Button variant="primary" type="submit" disabled={loading}>
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>

    )
}
