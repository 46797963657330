import { useState, useContext, useEffect, useRef, memo } from "react"
import { Card, Button, Modal, CloseButton, Spinner, Row, Col, Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import Icon from "../../controls/Icons/Icon";
import { dateDisplayFormater, SMSOrEmail } from "../../../Utils";
import BlockOrUnblockIPPopover from "../IPBlockList/BlockOrUnblockIPPopover";

const SMSEmailLog = ({ filter, smsoremail }) => {
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [logFilter, setLogFilter] = useState([]);
    const columns = useRef([]);
    useEffect(() => {
        setLogFilter(filter);
    }, [filter]);
    useEffect(() => {
        setRefreshGrid((a) => !a);
    }, [logFilter]);

    useEffect(() => {
        if (smsoremail == SMSOrEmail.SMS) {
            columns.current = [
                {
                    field: "objProject.ProjectName",
                    title: "Project",
                },
                {
                    field: "SentOn",
                    title: "Sent On",
                    sortable: true,
                    formatter: dateDisplayFormater,
                },
                {
                    field: "SMSEmailFor",
                    title: "SMS For",
                    sortable: true,
                },
                //{
                //    field: "Type",
                //    title: "Type",
                //    formatter: function (value, row) {
                //        if (row.SMSFlag && row.EmailFlag) {
                //            return <Badge bg={"warning"}>Both</Badge>
                //        }
                //        else if (row.SMSFlag) {
                //            return <Badge bg={"warning"}>SMS Only</Badge>
                //        }
                //        else if (row.EmailFlag) {
                //            return <Badge bg={"warning"}>Email Only</Badge>
                //        }
                //        return <Badge bg={"danger"}>Nothing tried</Badge>
                //    }
                //},
                {
                    field: "SMSStatus",
                    title: "SMS Status",
                    formatter: function (value, row) {
                        if (row.SMSFlag && value) {
                            return <Badge bg={"success"}>Sent</Badge>
                        }
                        else if (row.SMSFlag && !value) {
                            return <OverlayTrigger trigger="click" overlay={
                                <Popover>
                                    <Popover.Header as="h3">SMS Response</Popover.Header>
                                    <Popover.Body>
                                        {row.SMSResponse}
                                    </Popover.Body>
                                </Popover>
                            }>
                                <Badge bg={"danger"}>Failed</Badge>
                            </OverlayTrigger>

                        }
                        else {
                            return <Badge bg={"warning"}>Not Tried</Badge>
                        }
                    }
                },
                {
                    field: "Mobile",
                    title: "Mobile",
                    sortable: true,
                },
                {
                    field: "SMSContent",
                    title: "SMS Content",
                    sortable: true,
                },
                {
                    field: "strSMSDeliveryStatus",
                    title: "SMS Delivery Status",
                    sortable: true,
                    sortName:"SMSDeliveryStatus",
                },
                {
                    field: "SMSDeliveredOn",
                    title: "SMS Delivery On",
                    sortable: true,
                    formatter: dateDisplayFormater,
                },
                {
                    field: "IP",
                    title: "Access IP",
                    formatter: function (value, row) {
                        if (value)
                            return (
                                <OverlayTrigger
                                    trigger="click"
                                    rootClose={true}
                                    overlay={
                                        <BlockOrUnblockIPPopover ip={value} ProjectId={row.ProjectId} />
                                    }
                                >
                                    <a>{value}</a>
                                </OverlayTrigger>);
                        return "";
                    },
                },
            ];
        }
        else if (smsoremail == SMSOrEmail.Email) {
            columns.current = [
                {
                    field: "objProject.ProjectName",
                    title: "Project",
                },
                {
                    field: "SentOn",
                    title: "Sent On",
                    sortable: true,
                    formatter: dateDisplayFormater,
                },
                {
                    field: "SMSEmailFor",
                    title: "Email For",
                    sortable: true,
                },
                //{
                //    field: "Type",
                //    title: "Type",
                //    formatter: function (value, row) {
                //        if (row.SMSFlag && row.EmailFlag) {
                //            return <Badge bg={"warning"}>Both</Badge>
                //        }
                //        else if (row.SMSFlag) {
                //            return <Badge bg={"warning"}>SMS Only</Badge>
                //        }
                //        else if (row.EmailFlag) {
                //            return <Badge bg={"warning"}>Email Only</Badge>
                //        }
                //        return <Badge bg={"danger"}>Nothing tried</Badge>
                //    }
                //},
                {
                    field: "EmailStatus",
                    title: "Email Status",
                    formatter: function (value, row) {
                        if (row.EmailFlag && value) {
                            return <Badge bg={"success"}>Sent</Badge>
                        }
                        else if (row.EmailFlag && !value) {
                            return <Badge bg={"danger"}>Failed</Badge>
                        }
                        else {
                            return <Badge bg={"warning"}>Not Tried</Badge>
                        }
                    }
                },                
                {
                    field: "EmailFrom",
                    title: "Email From",
                    sortable: true,
                },
                {
                    field: "EmailTo",
                    title: "Email To",
                    sortable: true,
                },
                {
                    field: "EmailSubject",
                    title: "Email Subject",
                    sortable: true,
                },
                {
                    field: "strEmailDeliveryStatus",
                    title: "Email Delivery Status",
                    sortable: true,
                    sortName: "EmailDeliveryStatus",
                },
                {
                    field: "EmailDeliveredOn",
                    title: "Email Delivery On",
                    sortable: true,
                    formatter: dateDisplayFormater,
                },                
                {
                    field: "IP",
                    title: "Access IP",
                    formatter: function (value, row) {
                        if (value)
                            return (
                                <OverlayTrigger
                                    trigger="click"
                                    rootClose={true}
                                    overlay={
                                        <BlockOrUnblockIPPopover ip={value} ProjectId={row.ProjectId} />
                                    }
                                >
                                    <a>{value}</a>
                                </OverlayTrigger>);
                        return "";
                    },
                },
            ];
        }
        else {
            columns.current = [
                {
                    field: "objProject.ProjectName",
                    title: "Project",
                },
                {
                    field: "SentOn",
                    title: "Sent On",
                    sortable: true,
                    formatter: dateDisplayFormater,
                },
                {
                    field: "SMSEmailFor",
                    title: "SMS Email For",
                    sortable: true,
                },
                //{
                //    field: "Type",
                //    title: "Type",
                //    formatter: function (value, row) {
                //        if (row.SMSFlag && row.EmailFlag) {
                //            return <Badge bg={"warning"}>Both</Badge>
                //        }
                //        else if (row.SMSFlag) {
                //            return <Badge bg={"warning"}>SMS Only</Badge>
                //        }
                //        else if (row.EmailFlag) {
                //            return <Badge bg={"warning"}>Email Only</Badge>
                //        }
                //        return <Badge bg={"danger"}>Nothing tried</Badge>
                //    }
                //},
                {
                    field: "EmailStatus",
                    title: "Email Status",
                    formatter: function (value, row) {
                        if (row.EmailFlag && value) {
                            return <Badge bg={"success"}>Sent</Badge>
                        }
                        else if (row.EmailFlag && !value) {
                            return <Badge bg={"danger"}>Failed</Badge>
                        }
                        else {
                            return <Badge bg={"warning"}>Not Tried</Badge>
                        }
                    }
                },
                {
                    field: "SMSStatus",
                    title: "SMS Status",
                    formatter: function (value, row) {
                        if (row.SMSFlag && value) {
                            return <Badge bg={"success"}>Sent</Badge>
                        }
                        else if (row.SMSFlag && !value) {
                            return <OverlayTrigger trigger="click" overlay={
                                <Popover>
                                    <Popover.Header as="h3">SMS Response</Popover.Header>
                                    <Popover.Body>
                                        {row.SMSResponse}
                                    </Popover.Body>
                                </Popover>
                            }>
                                <Badge bg={"danger"}>Failed</Badge>
                            </OverlayTrigger>

                        }
                        else {
                            return <Badge bg={"warning"}>Not Tried</Badge>
                        }
                    }
                },
                {
                    field: "EmailFrom",
                    title: "Email From",
                    sortable: true,
                },
                {
                    field: "EmailTo",
                    title: "Email To",
                    sortable: true,
                },
                {
                    field: "EmailSubject",
                    title: "Email Subject",
                    sortable: true,
                },
                {
                    field: "strEmailDeliveryStatus",
                    title: "Email Delivery Status",
                    sortable: true,
                    sortName: "EmailDeliveryStatus",
                },
                {
                    field: "EmailDeliveredOn",
                    title: "Email Delivery On",
                    sortable: true,
                    formatter: dateDisplayFormater,
                },
                {
                    field: "Mobile",
                    title: "Mobile",
                    sortable: true,
                },
                {
                    field: "SMSContent",
                    title: "SMS Content",
                    sortable: true,
                },
                {
                    field: "strEmailDeliveryStatus",
                    title: "SMS Delivery Status",
                    sortName: "SMSDeliveryStatus",
                    sortable: true,
                },
                {
                    field: "SMSDeliveredOn",
                    title: "SMS Delivery On",
                    sortable: true,
                    formatter: dateDisplayFormater,
                },
                {
                    field: "IP",
                    title: "Access IP",
                    formatter: function (value, row) {
                        if (value)
                            return (
                                <OverlayTrigger
                                    trigger="click"
                                    rootClose={true}
                                    overlay={
                                        <BlockOrUnblockIPPopover ip={value} ProjectId={row.ProjectId} />
                                    }
                                >
                                    <a>{value}</a>
                                </OverlayTrigger>);
                        return "";
                    },
                },
            ];
        }
    }, [smsoremail]);


    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "SentOn",
        sortOrder: "desc",
        formatQueryParams: function (obj) {
            if (logFilter) {
                obj.filters = logFilter;
            }
            return obj;
        },
    };
    return (
        <>
            <div>
                {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                <DataGrid url={state.projectdetail.API + "/SMSEmailAdmin/Grid"} classes="table table-striped" options={options} columns={columns.current} refresh={refreshGrid} />
            </div>
        </>
    );
}

export default memo(SMSEmailLog);
