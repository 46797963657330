import React, { useEffect, memo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ErrorHandler from '../ErrorHandler';
import { Nav, Navbar, Collapse, ListGroup, Accordion, Dropdown, DropdownButton, Popover, OverlayTrigger } from 'react-bootstrap';
import Icon from '../Icons/Icon';
import SidebarMenuSkeleton from './SidebarMenuSkeleton';
const SidebarMenu = ({ heading, open }) => {
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const [menus, setMenus] = useState([]);
    const [submenus, setSubMenus] = useState([]);
    const [error, setError] = useState("");
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [openParentId, setOpenParentId] = useState(0);
    useEffect(() => {
        if (!state.access.sessionExpired) {
            setLoading(true);
            fetch(state.projectdetail.API + "/roleaccess/authorizedMenu", { credentials: 'include', method: "POST" })
                .then(res => res.json()).then((res) => {
                    let mainMenus = [];
                    let subMenus1 = [];
                    res.forEach(function (r) {
                        if (r.tblMenu.parentId == null || r.tblMenu.parentId == undefined) {
                            mainMenus.push(r);
                        }
                        else {
                            if (loc.pathname == r.tblMenu.path) {
                                setOpenParentId(r.tblMenu.parentId);
                            }
                            subMenus1.push(r);
                        }
                    });
                    setSubMenus(subMenus1);
                    setMenus(mainMenus);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setError(err);
                });
        }
    }, [state.access.sessionExpired]);
    useEffect(() => {
        if (submenus && submenus.length > 0) {
            let mdata = [...submenus];
            let noSubmenu = true;
            mdata.forEach(function (r) {
                if (loc.pathname == r.tblMenu.path) {
                    if (r.tblMenu.parentId) {
                        setOpenParentId(r.tblMenu.parentId);
                        noSubmenu = false;
                    }
                }
            });
            if (noSubmenu) {
                setOpenParentId(-1)
            }
        }
    }, [submenus, loc.pathname]);
    const getSubmenu = function (id) {
        let menuData = submenus.filter(function (m) {
            return m.tblMenu.parentId == id;
        });
        return menuData
    }
    const logout = () => {
        window.confirm("Do you want to logout?", function () {
            fetch(state.projectdetail.API + "/auth/logout", { method: "POST" }).then(() => {
                localStorage.removeItem("user");
                navigate("/");
            });
        });
    };

    const [parentOpenId, setParentOpenId] = useState(0);
    if (error) {
        return <ErrorHandler error={error} />
    }
    return (
        <div className='h-100'>
            <Nav className={(open ? (" pe-3 ps-3 ") : "sidebar-menu-submenu-closed ") + " col-md-12 sidebar-menu-content-menus"}>
                {loading ? <SidebarMenuSkeleton open={open} /> :
                    <div className='container p-0'>
                        {menus.map((menu, index) => {
                            if (menu.tblMenu.isParent) {
                                let cssParent = "";
                                if (openParentId == menu.tblMenu.Id) {
                                    cssParent = "active";
                                }
                                if (open) {
                                    return (
                                        <Accordion className='sidebar-menu-submenu' key={"Menu" + menu.tblMenu.Id} defaultActiveKey={openParentId}>
                                            <Accordion.Item eventKey={menu.tblMenu.Id}>
                                                <Accordion.Header className={cssParent}>
                                                    <Nav.Item>
                                                        <Nav.Link className={cssParent}><Icon name={menu.tblMenu.icon} />{open ? <span>&nbsp;{menu.tblMenu.menuName}</span> : ""}</Nav.Link>
                                                    </Nav.Item>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {open ? <ListGroup>
                                                        {getSubmenu(menu.tblMenu.Id).map((subMenu) => {
                                                            let cssclass = "";
                                                            if (loc.pathname == subMenu.tblMenu.path) {
                                                                cssclass = "active"
                                                            }
                                                            return (
                                                                <ListGroup.Item key={"SubMenu" + subMenu.tblMenu.Id}>
                                                                    <Nav.Item>
                                                                        <Nav.Link className={cssclass} as={Link} to={subMenu.tblMenu.path}><Icon name={subMenu.tblMenu.icon} />{open ? <span>&nbsp;{subMenu.tblMenu.menuName}</span> : ""}</Nav.Link>
                                                                    </Nav.Item>
                                                                </ListGroup.Item>
                                                            )
                                                        })}
                                                    </ListGroup> : ""}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    )
                                }
                                else {
                                    const popover = (
                                        <Popover className='sidebar-menu-popover'>
                                            <Popover.Body>
                                                <ListGroup>
                                                    {getSubmenu(menu.tblMenu.Id).map((subMenu) => {
                                                        let cssclass = "";
                                                        if (loc.pathname == subMenu.tblMenu.path) {
                                                            cssclass = "active"
                                                        }
                                                        return (
                                                            <ListGroup.Item key={"GroupSubMenu" + subMenu.tblMenu.Id}>
                                                                <Nav.Item>
                                                                    <Nav.Link className={cssclass} as={Link} to={subMenu.tblMenu.path}><Icon name={subMenu.tblMenu.icon} /><span>&nbsp;{subMenu.tblMenu.menuName}</span></Nav.Link>
                                                                </Nav.Item>
                                                            </ListGroup.Item>
                                                        )
                                                    })}
                                                </ListGroup>
                                            </Popover.Body>
                                        </Popover>
                                    )
                                    return (
                                        <>
                                            <OverlayTrigger key={"Trigger" + menu.tblMenu.Id} trigger="click" rootClose={true} placement="right" overlay={popover}>
                                                <Nav.Item>
                                                    <Nav.Link className={cssParent}><Icon name={menu.tblMenu.icon} />{open ? <span>&nbsp;{menu.tblMenu.menuName}</span> : ""}</Nav.Link>
                                                </Nav.Item>
                                            </OverlayTrigger>
                                        </>
                                    )
                                    //     return (
                                    //         <Nav.Item key={menu.tblMenu.Id}>
                                    //             <Nav.Link className={cssclass}><Icon name={menu.tblMenu.icon} />{open ? <span>&nbsp;{menu.tblMenu.menuName}</span> : ""}</Nav.Link>
                                    //             <Dropdown.Menu show>
                                    //                 {getSubmenu(menu.tblMenu.Id).map((subMenu) => {
                                    //                     cssclass = (loc.pathname == subMenu.tblMenu.path ? "active" : "")
                                    //                     return (
                                    //                         <Dropdown.Item eventKey="1" key={subMenu.tblMenu.Id}>
                                    //                             <Dropdown.Link className={cssclass} as={Link} to={subMenu.tblMenu.path}>
                                    //                                 <Icon name={subMenu.tblMenu.icon} />{open ? <span>&nbsp;{subMenu.tblMenu.menuName}</span> : ""}
                                    //                             </Dropdown.Link>
                                    //                         </Dropdown.Item>
                                    //                     )
                                    //                 })}
                                    //             </Dropdown.Menu>
                                    //         </Nav.Item>
                                    //     )
                                }
                            }
                            else {
                                let cssclass = "";
                                if (loc.pathname == menu.tblMenu.path) {
                                    cssclass = "active"
                                }
                                return (
                                    <Nav.Item key={"NavItem" + menu.tblMenu.Id}>
                                        <Nav.Link className={cssclass} as={Link} to={menu.tblMenu.path}><Icon name={menu.tblMenu.icon} />{open ? <span>&nbsp;{menu.tblMenu.menuName}</span> : ""}</Nav.Link>
                                    </Nav.Item>
                                )
                            }
                        })}
                    </div>
                }

            </Nav>
            <Nav className={(open ? (" pe-3 ps-3 ") : "") + ' sidebar-menu-submenu sidebar-menu-content-userinfo bg-link'}>
                <div className='container p-0'>
                    <ListGroup>
                        <ListGroup.Item className='p-0'>
                            <Nav.Item>
                                <Nav.Link className={(loc.pathname == "/admin/studentprofile" || loc.pathname == "/admin/profile") ? "active" : ""} as={Link} to={(state.access.user && state.access.user.fkRoleId == 2) ? "/admin/studentprofile" : "/admin/profile"} ><Icon name="BsPerson" />{open ? <span>&nbsp;Profile</span> : ""}</Nav.Link>
                            </Nav.Item>
                        </ListGroup.Item>
                        {/*<ListGroup.Item className='p-0'>*/}
                        {/*    <Nav.Item>*/}
                        {/*        <Nav.Link as={Link} to={(state.access.user && state.access.user.fkRoleId == 2) ? "/admin/studentprofile" : "/admin/profile"} ><Icon name="AiOutlineMail" />{open ? <span>&nbsp;Notifications</span> : ""}</Nav.Link>*/}
                        {/*    </Nav.Item>*/}
                        {/*</ListGroup.Item>*/}
                        {/*<ListGroup.Item className='p-0'>*/}
                        {/*    <Nav.Item>*/}
                        {/*        <Nav.Link className={(loc.pathname == "/admin/settings") ? "active" : ""} as={Link} to={"/admin/settings"} ><Icon name="BsFillGearFill" />{open ? <span>&nbsp;Settings</span> : ""}</Nav.Link>*/}
                        {/*    </Nav.Item>*/}
                        {/*</ListGroup.Item>*/}
                        <ListGroup.Item className='p-0'>
                            <Nav.Item>
                                <Nav.Link onClick={logout}><Icon name="BsPower" />{open ? <span>&nbsp;Log Out</span> : ""}</Nav.Link>
                            </Nav.Item>
                        </ListGroup.Item>
                    </ListGroup>
                </div>
            </Nav>
        </div>
    );
}

export default memo(SidebarMenu);
