import React from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FPGetOTPForm from './FPGetOTPForm';
import FPResendOTPForm from './FPResendOTPForm';
import ResetPasswordForm from './ResetPasswordForm';

const ForgotPasswordForm = ({afterResetLogin}) => {
  const navigate = useNavigate();
  const [mobileno, setMobileno] = useState("");
  const [showFPGetOTP, setShowFPGetOTP] = useState(true);
  const [showFPResendOTP, setShowFPResendOTP] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleOTP = ((mobileno) => {
    setShowFPGetOTP(false);
    setShowFPResendOTP(true);
    setShowResetPassword(false);
    setMobileno(mobileno);
  })
  const handleResetPassword = (() => {
    setShowFPGetOTP(false);
    setShowFPResendOTP(false);
    setShowResetPassword(true);
  })
  const afterReset = (()=>{
    //navigate('/login');    
    afterResetLogin();
  });
  return (
    <div >
      {showFPGetOTP ? <FPGetOTPForm showHide={handleOTP} /> : null}
      {showFPResendOTP ? <FPResendOTPForm mobile={mobileno} showRP={handleResetPassword} /> : null}
      {showResetPassword ? <ResetPasswordForm mobile={mobileno} heading={"Create New Password"} afterReset={afterReset} /> : null}
    </div>
  );
}

export default memo(ForgotPasswordForm);
