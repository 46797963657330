export const POAction = {
    setPO: "setPO",
    resetPO: "resetPO",
    setPODetails: "setPODetails",
    deletePODetail: "deletePODetail",
}
const initialPO = {
    ProjectId: "",
    FkPOTemplateId: 1,
    Id: 0,
    PONumberFormat: "",
    DistinctID: "",
    POFrom: "Bow and Baan Technology Solutions Pvt. Ltd.",
    FromAddress: "#5, Vembuliamman Koil, New 1st cross street, Virugambakkam, Chennai, Tamil Nadu- 600092. India",
    vendor: { Id: 0, VendorName: "" },
    PODate: "",
    Quatity: null,
    Tax: null,
    TotalAmount: null,
    PaymentTerms: "",
    TermConditions: "",
    POFromReferenceId: "-1",
    POToReferenceId: "",
    ProjectName: "",
    PODetails: [
        {
            Id: 0,
            Description: "",
            SubDescription: "",
            Quatity: null,
            Rate: null,
            Tax: null,
            TotalAmount: null,
            Deadline: "",
        },
    ],
    PODetailDeletedIds: [],
};

const POReducer = (po = initialPO, actions) => {
    switch (actions.type) {
        case POAction.setPO:
            if (actions.payload.PODate) {
                actions.payload.PODate = actions.payload.PODate.split("T")[0];
            }
            return { ...po, ...actions.payload };
        case POAction.resetPO:
            return Object.assign({}, initialPO);
        case POAction.setPODetails:
            let quaT = 0;
            let taxT = 0;
            let amtT = 0;
            if (actions.payload && actions.payload.length) {
                actions.payload.forEach(function (podrow) {
                    if (podrow.Quantity)
                        quaT += parseFloat(podrow.Quantity);
                    if (podrow.Tax)
                        taxT += parseFloat(podrow.Tax);
                    if (podrow.TotalAmount)
                        amtT += parseFloat(podrow.TotalAmount);
                });
            }
            return { ...po, "PODetails": actions.payload, TotalAmount: amtT, Tax: taxT, Quatity: quaT };
        case POAction.deletePODetail:
            let Data = JSON.parse(JSON.stringify(po.PODetails));
            let PODetailDeletedIds = JSON.parse(JSON.stringify(po.PODetailDeletedIds)) || [];
            if (actions.payload.row && actions.payload.row.Id) {
                PODetailDeletedIds.push(actions.payload.row.Id);
                Data = Data.filter((d, dindex) => {
                    return d.Id != actions.payload.row.Id;
                });
            }
            else {
                Data = Data.filter((d, dindex) => {
                    return dindex != actions.payload.index;
                });
            }
            return { ...po, "PODetails": Data, "PODetailDeletedIds": PODetailDeletedIds };
        default:
            return po;
    }
}
export default POReducer;