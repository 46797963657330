import React, { memo, useEffect, useState } from 'react';
import { Row, Col, Button, CloseButton, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { AccessAction } from '../../../Reducer/AccessReducer';
import Icon from '../Icons/Icon';
import "./LoggedUserDetail.css";
const LoggedUserDetail = ({ handleClose }) => {
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const logout = () => {
        window.confirm("Do you want to logout?", function () {
            fetch(state.projectdetail.API + "/auth/logout", { method: "POST" }).then(() => {
                localStorage.removeItem("user");
                navigate("/");
            });
        });
    };
    const setTheme = (e) => {
        let value = e.target.value;
        let obj = state.access;
        obj.user.theme = value;
        dispatch({ type: AccessAction.setAccess, payload: { ...obj } });
    }
    return (
        <div className='logged-in-user'>
            <div className='logged-in-user-info mb-3'>
                <div className='container p-3'>
                    <Row>
                        <Col>
                            <h5 className='d-inline-block'>
                                Settings
                            </h5>
                            <CloseButton className='float-end' onClick={handleClose} />
                        </Col>
                    </Row>


                    {/* <Row>
                        <Col className='col-auto'>
                            {state.access.user.photo ? <img src={("../profile/" + state.access.user.photo)} width="55" /> : <span className='profile-icon'><Icon name="BsPersonCircle" /></span>}
                        </Col>
                        <Col>
                            <Row>
                                <Col className='fw-bold'>
                                    {state.access.user && state.access.user.name}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='fw-light'>
                                    {state.access.user && state.access.user.email}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='fw-light'>
                                    +91 {state.access.user && state.access.user.mobile}
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                <Col className='col-auto'>
                                    <Link to="" className='text-primary'>
                                        <Icon name="BsPerson" />&nbsp;Profile
                                    </Link>
                                </Col>
                                <Col className='col-auto'>
                                    <a onClick={logout} className="text-danger">
                                        <Icon name="BsPower" />&nbsp;Logout
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}


                    <Row>
                        <Col className='text-center'>
                            {state.access.user.photo ? <img src={("../profile/" + state.access.user.photo)} width="55" /> : <span className='profile-icon'><Icon name="BsPersonCircle" /></span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center fw-bold'>
                            {state.access.user && state.access.user.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center fw-light'>
                            {state.access.user && state.access.user.email}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center fw-light'>
                            +91 {state.access.user && state.access.user.mobile}
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col className='col-auto'>
                            <Link to={(state.access.user && state.access.user.fkRoleId == 2) ? "/admin/studentprofile" : "/admin/profile"} className='text-primary'>
                                <Icon name="BsPerson" />&nbsp;Profile
                            </Link>
                        </Col>
                        <Col className='col-auto'>
                            <a onClick={logout} className="text-danger">
                                <Icon name="BsPower" />&nbsp;Logout
                            </a>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <div className='container p-3'>
                    <Row>
                        <Col>
                            <h6 className='form-heading'>Themes</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-auto mb-3'>
                            <Form.Check className='theme-radio'
                                label={<div className='bg-light p-4 rounded-circle'></div>}
                                checked={"light" == (state.access.user.theme || "light")}
                                type={"radio"}
                                name="theme"
                                value="light"
                                id={`theme-light`}
                                onChange={setTheme}
                            />
                        </Col>
                        <Col className='col-auto mb-3'>
                            <Form.Check className='theme-radio'
                                label={<div className='bg-dark p-4 rounded-circle'></div>}
                                checked={"dark" == (state.access.user.theme || "light")}
                                type={"radio"}
                                name="theme"
                                value="dark"
                                id={`theme-dark`}
                                onChange={setTheme}
                            />
                        </Col>
                        <Col className='col-auto mb-3'>
                            <Form.Check className='theme-radio'
                                label={<div className='bg-primary p-4 rounded-circle'></div>}
                                checked={"primary" == (state.access.user.theme || "light")}
                                type={"radio"}
                                name="theme"
                                value="primary"
                                id={`theme-primary`}
                                onChange={setTheme}
                            />
                        </Col>
                        <Col className='col-auto mb-3'>
                            <div className='bg-danger p-4 rounded-circle'>
                            </div>
                        </Col>
                        <Col className='col-auto mb-3'>
                            <div className='bg-warning p-4 rounded-circle'>
                            </div>
                        </Col>
                        <Col className='col-auto mb-3'>
                            <div className='bg-success p-4 rounded-circle'>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
export default memo(LoggedUserDetail);