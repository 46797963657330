import React, { memo, useEffect, useState } from 'react'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import ServerError from '../../controls/ServerError';
import Icon from '../../controls/Icons/Icon';
import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
function IPBlockListForm({ _inputValue, afterSave }) {
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        IPAddress: "",
        ProjectId: "",        
        Id: 0,
    });

    const [serverError, setServerError] = useState({});
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);
    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            fetch(state.projectdetail.API + "/IPBlockList/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputValue)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    setInputValue({
                        IPAddress: "",
                        ProjectId: "",
                        Id: 0,
                    });
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    })    
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={4}>
                    <Form.Group>
                        <Form.Label>Project<code className='required-symbol'>*</code></Form.Label>
                        <DynamicDropdownSearchable required={true} addAttr={["PONumberFormat"]} from={state.projectdetail.API + "/Project/GetAll"} label="ProjectName" value="Id" fetchType="get" name="ProjectId" onDropdownChange={handleChange} defaultval={inputValue.ProjectId} />
                        {validated && !inputValue.ProjectId && <div className='invalid-msg'>Select project</div>}
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group>
                        <Form.Label>IP Address <code className='required-symbol'>*</code></Form.Label>
                        <Form.Control required type="text" name="IPAddress" value={inputValue.IPAddress} onChange={handleChange} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please enter ip address</Form.Control.Feedback>
                    </Form.Group>
                </Col>                
            </Row>
            <Row className="mb-3">
                <Col>
                    <ServerError error={serverError} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={12} className="text-center">
                    <Button variant="primary" type="submit" disabled={loading}>
                        Done
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}
export default memo(IPBlockListForm);