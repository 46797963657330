import { memo, useState } from "react";
import { Form } from "react-bootstrap";
const FormEmail = ({ name, required, errorMessage, onChange, value, ...otherProps }) => {
    return (
        <>
            <Form.Control
                pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                required={required}
                type="email"
                name={name}
                value={value}
                onChange={onChange}
                autoComplete="off" {...otherProps} />
            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
        </>
    )
}
export default memo(FormEmail);