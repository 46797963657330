import { useState } from "react"
import { Card, Button, Modal, CloseButton, Spinner, Form, Row, Col, Badge } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useDispatch, useSelector } from 'react-redux';
import POForm from './POForm';
import Icon from "../../controls/Icons/Icon";
import { dateDisplayFormater } from "../../../Utils";
import { POAction } from "../../../Reducer/POReducer";
import POImport from "./POImport";
import CurrencyLabel from "../../controls/CurrencyLabel";

const PO = () => {
    const state = useSelector(state => state);

    const dispatch = useDispatch();
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);

    const [sendInput, setSendInput] = useState({
        Id: 0,
        From: "",
        CC: "",
        BCC: "",
        Subject: "",
    });
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const [sendValidated, setSendValidated] = useState(false);
    const [showPOModal, setShowPOModal] = useState(false);
    const handlePOModalClose = () => setShowPOModal(false);
    const [showSendPOModal, setShowSendPOModal] = useState(false);
    const handleSendPOModalClose = () => setShowSendPOModal(false);

    const [showPOImportModal, setShowPOImportModal] = useState(false);
    const handlePOImportModalClose = () => setShowPOImportModal(false);

    const openImportPO = () => {
        setShowPOImportModal(true);
    };

    const [POContent, setPOContent] = useState("");
    const handleView = (row) => {
        setLoading(true);
        fetch(state.projectdetail.API + "/POAdmin/GetPOContent?Id=" + row.Id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        }).then(response => response.text()).then((res) => {
            setShowPOModal(true);
            setPOContent(res);
        }).catch((err) => {
            window.alert(err);
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleSendChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setSendInput(c => ({ ...c, [name]: value }));
    });
    const handleSend = (row) => {
        setSendInput({
            Id: row.Id,
            From: row.objProject.SenderMailId,
            CC: "",
            BCC: "",
            Subject: replaceTags(row.poTemplate.EmailSubject, row),
        });
        setShowSendPOModal(true);
    }
    const replaceTags = (str, obj) => {
        if (!str)
            return "";
        if (!obj || Object.keys(obj).length == 0)
            return str;
        for (let key in obj) {
            const pattern = new RegExp('{' + key + '}', 'gi');
            str = str.replace(pattern, obj[key]);
        }
        return str;
    }
    const onSendSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setSendValidated(true);
        }
        else {
            setLoading(true);
            let url = state.projectdetail.API + "/POAdmin/SendPONotification?Id=" + sendInput.Id;
            if (sendInput.CC)
                url += "&CC=" + sendInput.CC;
            if (sendInput.BCC)
                url += "&BCC=" + sendInput.BCC;
            if (sendInput.Subject)
                url += "&Subject=" + sendInput.Subject;
            if (sendInput.From)
                url += "&From=" + sendInput.From;
            fetch(url,
                {
                    method: "GET",
                }).then(response => response.json()).then((res) => {
                    if (res.Succeeded) {
                        setSendInput({
                            Id: 0,
                            From: "",
                            CC: "",
                            BCC: "",
                            Subject: "",
                        });
                        setShowSendPOModal(false);
                        setRefreshGrid(r => !r);
                    }
                    window.alert(res.Message);
                }).catch((err) => {
                    window.alert(err);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }
    const columns = [
        {
            dataField: "remove",
            title: "",

            formatter: (cellContent, row) => {
                return (<div>
                    {state.access.edit ? <Button
                        className="btn-xs me-2 mb-2 p-0" variant="link"
                        onClick={() => handleEdit(row)}
                    ><Icon name="BsPencilFill" className='fs-5' /></Button> : ""}

                    {state.access.delete ? <Button variant="link"
                        className="btn-xs mb-2 p-0"
                        onClick={() => handleDelete(row)}
                    >
                        <Icon name="BsTrash" className='fs-5' />
                    </Button> : ""}
                </div>
                );
            },
        },
        {
            dataField: "view",
            title: "",
            formatter: (cellContent, row) => {
                return (<div>
                    <Button
                        className="btn-xs me-2 mb-2 p-0" variant="link"
                        onClick={() => handleView(row)}
                    ><Icon name="BsFillEyeFill" className='fs-5' /></Button>
                    <Button variant="link"
                        className="btn-xs mb-2 p-0"
                        onClick={() => handleSend(row)}
                    >
                        <Icon name="BiSend" className='fs-5' />
                    </Button>
                </div>
                );
            },
        },
        {
            field: "CreatedDate",
            title: "Created Date",
            sortable: true,
            formatter: function (value) {
                return dateDisplayFormater(value);
            },
        },
        {
            field: "objProject.ProjectName",
            title: "Project",
            filterable: true,
        },
        {
            field: "PONo",
            title: "PO No",
            sortable: true,
            filterable: true,
            formatter: (value) => {
                return <span className="text-nowrap">{value}</span>
            }
        },
        //{
        //    field: "POFrom",
        //    title: "PO From",
        //    sortable: true,
        //},
        {
            field: "vendor.VendorName",
            title: "PO To",
            sortable: true,
            filterable: true,
        },
        {
            field: "vendor.VendorEmailID",
            title: "PO To Email",
            sortable: false,
            filterable: true,
        },
        {
            field: "PODate",
            title: "PO Date",
            sortable: true,
            formatter: function (value) {
                return dateDisplayFormater(value);
            },
        },
        {
            field: "TotalAmount",
            title: "Total Amount",
            sortable: true,
            sendExportColumns: true,
            formatter: function (value) {
                return <CurrencyLabel text={value} />
            }
        },
        {
            field: "poStatus.POStatusName",
            title: "Status",
            sortable: true,
            sortName: "FkPOStatusId",
            formatter: function (value, row) {
                if (value) {
                    switch (row.FkPOStatusId) {
                        case 1:
                            return <Badge bg="danger">{value}</Badge>
                            break;
                        case 2:
                            return <Badge bg="primary">{value}</Badge>
                            break;
                        case 3:
                            return <Badge bg="warning">{value}</Badge>
                            break;
                        case 4:
                            return <Badge bg="success">{value}</Badge>
                            break;
                        case 5:
                            return <Badge bg="danger">{value}</Badge>
                            break;
                        case 6:
                            return <Badge bg="success">{value}</Badge>
                            break;
                    }
                }
                else
                    return <></>
            }
        },
    ];

    const handleEdit = (res) => {
        dispatch({ type: POAction.setPO, payload: res });
        setShowModal(true);
    };

    const handleDelete = ((res) => {
        window.confirm("Do you want to delete the PO?", function () {
            setLoading(true);
            let obj = {};
            obj.Id = res.Id;
            fetch(state.projectdetail.API + "/POAdmin/delete?Id=" + res.Id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {

                if (res.Succeeded) {
                    window.alert("Deleted successfuly");
                    setRefreshGrid(!refreshGrid);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }

            }).catch((err) => {
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        })

    });

    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "CreatedDate,PONo",
        filter: true,
        search: false,
        sortOrder: "desc,desc",
        onDoubleClickRow: function (row) {
            //dispatch({ type: POAction.setPO, payload: row });
            //navigate("/admin/po/" + row.Id);
        },
    };

    const handleAdd = () => {
        dispatch({ type: POAction.resetPO });
        setShowModal(true)
    };
    const handleAfter = (res) => {
        if (res.Succeeded) {
            window.alert("PO saved successfully");
            handleClose();
            setRefreshGrid(!refreshGrid);
        }
        else {
            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
    };
    return (
        <>
            <h4 className='form-heading mb-4'>
                Purchase order
                <div className='d-inline-block float-end'>
                    <Button onClick={handleAdd} className="me-2">Add PO</Button>
                    <Button onClick={openImportPO}>Import <Icon name="BsUpload" /></Button>
                </div>
            </h4>
            <Card>
                <Card.Body>
                    {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                    <DataGrid url={state.projectdetail.API + "/POAdmin/grid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose} backdrop="static" size="xl">
                <Modal.Header>
                    <h4>{state.po.Id ? "Edit " : "Add "} PO</h4>
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <POForm afterSave={handleAfter} onCancel={handleClose} />
                </Modal.Body>
            </Modal>

            <Modal show={showPOModal} onHide={handlePOModalClose} backdrop="static" size="xl">
                <Modal.Header>
                    <h4>PO</h4>
                    <CloseButton onClick={handlePOModalClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: POContent }} />
                </Modal.Body>
            </Modal>
            <Modal show={showSendPOModal} onHide={handleSendPOModalClose} backdrop="static" size="lg">
                <Modal.Header>
                    <h4>PO Email Notification</h4>
                    <CloseButton onClick={handleSendPOModalClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={sendValidated} onSubmit={onSendSubmit}>
                        <Row className="mb-3">
                            <Form.Group>
                                <Form.Label>From Email Id</Form.Label>
                                <Form.Control type="text" name="From" value={sendInput.From} onChange={handleSendChange}
                                    autoComplete="off" />
                                <Form.Control.Feedback type="invalid">From Email is required</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group>
                                <Form.Label>Subject</Form.Label>
                                <Form.Control type="text" name="Subject" value={sendInput.Subject} onChange={handleSendChange}
                                    autoComplete="off" />
                                <Form.Control.Feedback type="invalid">Subject is required</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group>
                                <Form.Label>CC</Form.Label>
                                <Form.Control type="text" name="CC" value={sendInput.CC} onChange={handleSendChange}
                                    autoComplete="off" />
                                <Form.Control.Feedback type="invalid">CC Email is required</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group>
                                <Form.Label>BCC</Form.Label>
                                <Form.Control type="text"
                                    name="BCC" value={sendInput.BCC} onChange={handleSendChange}
                                    autoComplete="off" />
                                <Form.Control.Feedback type="invalid">BCC Email is required</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Col className='text-center'>
                                <Button variant="outline-primary" className="me-2" onClick={handleSendPOModalClose} disabled={loading}>
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit" disabled={loading}>
                                    Send
                                    {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showPOImportModal} onHide={handlePOImportModalClose} size="xl">
                <Modal.Header>
                    <h4>PO</h4>
                    <CloseButton onClick={handlePOImportModalClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <POImport />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PO;
