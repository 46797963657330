import { useState, useContext, useEffect, useRef, memo } from "react"
import { Card, Button, Modal, CloseButton, Spinner, Row, Col } from 'react-bootstrap';
import SMSEmailLog from "./SMSEmailLog";

const SMSEmailLogModal = ({ filter, show, smsoremail }) => {
    const [logFilter, setLogFilter] = useState([]);
    const [showLogModal, setShowLogModal] = useState(false);
    const handleLogModalClose = () => setShowLogModal(false);

    useEffect(() => {
        setLogFilter(filter);
        setShowLogModal(show);
    }, [filter, show]);

    return (
        <Modal show={showLogModal} size="fullscreen" onHide={handleLogModalClose}>
            <Modal.Header>
                SMS Email Log
                <CloseButton onClick={handleLogModalClose} className="btn-close" />
            </Modal.Header>
            <Modal.Body>
                <SMSEmailLog filter={logFilter} smsoremail={smsoremail} />
            </Modal.Body>
        </Modal>
    );
}

export default memo(SMSEmailLogModal);