import { memo } from "react";
import { CloseButton, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getExtension, getFileIcon } from "../../../Utils";
import Icon from "../Icons/Icon";

const FileUploadViewUploaded = ({ onRemoveUploadedFile, index, folderpath, fileName }) => {
    const state = useSelector(state => state);
    return (
        <>
            <div className='border-bottom' key={index}>
                <Row className={index == 0 ? "" : "mt-3"}>
                    <Col className='col-auto pe-0 fileupload-files-uploaded-icon'>
                        <Icon name={getFileIcon(getExtension(fileName))} className="fs-2" />
                    </Col>
                    <Col className='col-8'>
                        <div className='fileupload-files-uploaded-filename text-truncate'>
                            <span className=''>{fileName}</span>
                        </div>
                        <div className='fileupload-files-uploaded-filesize'>
                            <a href={state.projectdetail.API + "/filesadmin/load?path=" + folderpath + fileName} target="_blank">View</a>
                        </div>
                    </Col>
                    <Col className='col-auto d-flex justify-content-center align-items-center'>
                        <CloseButton onClick={() => { onRemoveUploadedFile.call(null, index) }} />
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default memo(FileUploadViewUploaded);