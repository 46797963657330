import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, CloseButton } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import RoleForm from './RoleForm';
import { BsPencilFill, BsTrash } from 'react-icons/bs';
import Icon from "../../controls/Icons/Icon";

const Role = () => {
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);

    const [refreshGrid, setRefreshGrid] = useState(false);
    const [inputValue, setInputValue] = useState({
    });
    const [columns, setColumns] = useState([
        {
            field: "roleName",
            title: "Role",
            sortable: true,
        },
        {
            field: "description",
            title: "Description"
        },
        {
            dataField: "remove",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (<div>
                    {state.access.edit ? <Button
                        className="btn-xs me-2 mb-2 p-0" variant="link"
                        onClick={() => handleEdit(row)}
                    ><BsPencilFill className='fs-5' /></Button> : ""}

                    {state.access.delete ? <Button variant="link"
                        className="btn-xs mb-2 p-0"
                        onClick={() => handleDelete(row)}
                    >
                        <BsTrash className='fs-5' />
                    </Button> : ""}
                </div>
                );
            },
        }
    ]);
    const [options, setOptions] = useState({
        dataType: "server",
        showExport: true,
        showRefresh: true,        
        sortName: "roleName",
        sortOrder: "asc",
    });
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);

    const handleEdit = (res) => {
        setInputValue(res)
        setShowModal(true)
    };

    const handleDelete = ((res) => {
        window.confirm("Do you want to delete the role?", function () {
            setLoading(true);
            let obj = {};
            obj.Id = res.Id;
            fetch(state.projectdetail.API + "/role/delete?Id=" + res.Id, {            
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                {
                    if (res.Succeeded) {
                        window.alert("Deleted successfuly");
                        //setRefreshGrid(!refreshGrid);
                        setRefreshGrid(r => !r);
                    }
                    else {
                        //window.alert(state.projectdetail.ErrorMessage);
                        window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                    }
                }
            }).catch((err) => {
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        })
    });

    const handleAdd = () => {
        setInputValue([])
        setShowModal(true)
    };
    const handleAfter = (res) => {
        if (res.Succeeded) {
            window.alert("Role saved successfully");
            handleClose();
            setRefreshGrid(!refreshGrid);
        }
        else {
            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
    };
    return (
        <>
            <h4 className='form-heading mb-4'>
                Role & Access
                <div className='d-inline-block float-end'>
                {state.access.add ?<Button onClick={handleAdd}>Add <Icon name="BsPersonPlus" /></Button> : ""}
                </div>
            </h4>
            <Card>
                <Card.Body>                    
                    <DataGrid url={state.projectdetail.API + "/role/grid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header>
                    {inputValue.Id ? "Edit " : "Add "} Role
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <RoleForm afterSave={handleAfter} _inputValue={inputValue} onCancel={handleClose} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Role;
