import React from 'react';
import { Container, Row, Card, Col } from 'react-bootstrap'
import LoginForm from './LoginForm';
import { useSelector } from 'react-redux';
import { BsLockFill } from 'react-icons/bs';
import './Login.css'
import ForgotPasswordForm from './ForgotPasswordForm';
import { useState, memo } from 'react';
import { Navigate } from 'react-router-dom';
const Login = () => {
  const state = useSelector(state => state);
  const [showLogin,setShowLogin] = useState(true);
  const [showForgotPassword,setShowForgotPassword] = useState(false);
  const openForgotPassword = ((e) => {
    setShowLogin(false);
    setShowForgotPassword(true);    
  })
  const afterResetLogin = (()=>{    
    setShowLogin(true);
    setShowForgotPassword(false); 
  });
  return (
    <Container className='min-vh-100'>
      <Row className='d-flex min-vh-100 align-items-center'>
        <Col md={4}></Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className='text-center mb-4'>
                <img src="logo.png" className='login-logo' />
              </div>
              { showLogin ?
              <div>
              <h2 className='heading text-center mb-2 color-theme1'>Welcome to {state.projectdetail.ProjectName}</h2>
              {/* <div className='text-center mb-4'>
                <h6 className='small-heading text-center mb-4'>Nice to meet you again</h6>
              </div> */}
              </div>
              : null }

              { showLogin ? <LoginForm /> : null }
              { showForgotPassword ? <ForgotPasswordForm afterResetLogin={afterResetLogin} /> : null }  
              { showLogin ?            
              <Row className="mb-4">
                <Col>
                  <div className='text-center'>
                    <a className='link' onClick={openForgotPassword}><BsLockFill />Forgot Password ?</a>
                  </div>
                </Col>
              </Row>
              : null }
              <Row className="mb-3">
                <Col>
                  <div className='text-center'>
                    <span className='link poweredby'>Powered By {state.projectdetail.PoweredBy}</span>
                  </div>
                </Col>
              </Row>
            </Card.Body>            
          </Card>
        </Col>
        <Col md={4}></Col>
      </Row>
    </Container>
  );
}

export default Login;
