import { useState } from "react";
import { Alert, Button, Card, Col, Form, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import DataGrid from "../../controls/DataGrid.js/DataGrid";
import DynamicDropdownSearchable from "../../controls/DynamicDropdownSearchable";
import Icon from "../../controls/Icons/Icon";

const POImport = () => {
    const state = useSelector(state => state);
    //const columns = [
    //    {
    //        field: "",
    //        title: ""
    //    },
    //    {
    //        field: "",
    //        title: ""
    //    },
    //];
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
    });
    const handleProjectSelect = ((event) => {
        const selectedOption = event.target.selectedOptions[0];
        const selecteText = selectedOption.getAttribute("data-PONumberFormat");
        setInputValue(c => ({ ...c, ["PONumberFormat"]: selecteText }));
        handleChange(event);
    });
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    const [data, setData] = useState([]);
    const [invalidData, setInvalidData] = useState([]);
    const [validData, setValidData] = useState([]);

    const columns = [
        {
            field: "POFrom",
            title: "PO From",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "FromAddress",
            title: "From Address",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "POTo",
            title: "PO To",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "ToAddress",
            title: "To Address",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "Email",
            title: "Email",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "Mobile",
            title: "Mobile",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "PODate",
            title: "PO Date",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "Quatity",
            title: "Quatity",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "Tax",
            title: "Tax",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "TotalAmount",
            title: "Total Amount",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "TermConditions",
            title: "Term Conditions",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;

            },
        },
        {
            field: "PaymentTerms",
            title: "Payment Terms",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
        {
            field: "errorMessage",
            title: "Validataion Message",
            formatter: function (value, row) {
                if (!row.status) {
                    return <span className="text-danger">{value}</span>
                }
                return value;
            },
        },
    ];
    const options = {
        dataType: "client",
        showExport: false,
        showRefresh: false,
    };
    const validatePO = (row) => {
        if (!row["POFrom"]) {
            return { status: false, errorMessage: "PO from is required", ...row };
        }
        //if (!row["POTo"]) {
        //    return { status: false, errorMessage: "PO to is required", ...row };
        //}

        if (!row["TotalAmount"]) {
            return { status: false, errorMessage: "Total Amount is required", ...row };
        }
        if (row["TotalAmount"] <= 0) {
            return { status: false, errorMessage: "Total Amount is invalid", ...row };
        }

        if (!row["Email"]) {
            return { status: false, errorMessage: "Email is required", ...row };
        }
        //if (!row["Mobile"]) {
        //    return { status: false, errorMessage: "Mobile is required", ...row };
        //}
        if (!row["PODetails"] || row["PODetails"].length == 0) {
            return { status: false, errorMessage: "PO Details is required", ...row };
        }
        let totalAmount = 0;
        for (let loop = 0; loop < row["PODetails"].length; loop++) {
            const podetail = row["PODetails"][loop];
            if (!podetail["Description"]) {
                return { status: false, errorMessage: "Detail Description is required", ...row };
            }
            if (!podetail["Quantity"]) {
                return { status: false, errorMessage: "Detail Quantity is required", ...row };
            }
            if (!podetail["TotalAmount"]) {
                return { status: false, errorMessage: "Detail Total Amount is required", ...row };
            }
            totalAmount += parseInt(podetail["TotalAmount"]);
        }
        if (totalAmount != row["TotalAmount"]) {
            return { status: false, errorMessage: "Amount is mismatch with details", ...row };
        }
        return { status: true, ...row };
    }
    const onFileChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            let filename = e.target.files[0].name;
            let ext = filename.split(".").pop().toLowerCase();
            if (ext == "xls" || ext == "xlsx") {
                setInvalidFile(false);
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = reader.result;
                    const workBook = XLSX.read(data, { type: 'binary', cellText: false, cellDates: true, });
                    const readData = workBook.SheetNames.reduce((initial, name) => {
                        const sheet = workBook.Sheets[name];
                        initial[name] = XLSX.utils.sheet_to_json(sheet, { raw: false, dateNF: "yyyy-MM-dd" });
                        return initial;
                    }, {});
                    if (readData && Object.keys(readData).length > 0) {
                        const json = readData[Object.keys(readData)[0]];
                        const invalid = [];
                        const valid = [];
                        const dddd = [];
                        json.map(function (row) {
                            row.PONumberFormat = inputValue.PONumberFormat;
                            row.PODetails = [];
                            if (row.Description1 || row.Quantity1 || row.TotalAmount1) {
                                let podetail1 = {};
                                podetail1.Description = row.Description1;
                                podetail1.SubDescription = row.SubDescription1;
                                podetail1.Quantity = row.Quantity1;
                                podetail1.Rate = row.Rate1;
                                podetail1.Tax = row.Tax1;
                                podetail1.TotalAmount = row.TotalAmount1;
                                podetail1.Deadline = row.Deadline1;

                                delete row.Description1;
                                delete row.SubDescription1;
                                delete row.Quantity1;
                                delete row.Rate1;
                                delete row.Tax1;
                                delete row.TotalAmount1;
                                delete row.Deadline1;
                                row.PODetails.push(podetail1);
                            }

                            if (row.Description2 || row.Quantity2 || row.TotalAmount2) {
                                let podetail2 = {};
                                podetail2.Description = row.Description2;
                                podetail2.SubDescription = row.SubDescription2;
                                podetail2.Quantity = row.Quantity2;
                                podetail2.Rate = row.Rate2;
                                podetail2.Tax = row.Tax2;
                                podetail2.TotalAmount = row.TotalAmount2;
                                podetail2.Deadline = row.Deadline2;

                                delete row.Description2;
                                delete row.SubDescription2;
                                delete row.Quantity2;
                                delete row.Rate2;
                                delete row.Tax2;
                                delete row.TotalAmount2;
                                delete row.Deadline2;
                                row.PODetails.push(podetail2);
                            }

                            if (row.Description3 || row.Quantity3 || row.TotalAmount3) {
                                let podetail3 = {};
                                podetail3.Description = row.Description3;
                                podetail3.SubDescription = row.SubDescription3;
                                podetail3.Quantity = row.Quantity3;
                                podetail3.Rate = row.Rate3;
                                podetail3.Tax = row.Tax3;
                                podetail3.TotalAmount = row.TotalAmount3;
                                podetail3.Deadline = row.Deadline3;

                                delete row.Description3;
                                delete row.SubDescription3;
                                delete row.Quantity3;
                                delete row.Rate3;
                                delete row.Tax3;
                                delete row.TotalAmount3;
                                delete row.Deadline3;
                                row.PODetails.push(podetail3);
                            }

                            if (row.Description4 || row.Quantity4 || row.TotalAmount4) {
                                let podetail4 = {};
                                podetail4.Description = row.Description4;
                                podetail4.SubDescription = row.SubDescription4;
                                podetail4.Quantity = row.Quantity4;
                                podetail4.Rate = row.Rate4;
                                podetail4.Tax = row.Tax4;
                                podetail4.TotalAmount = row.TotalAmount4;
                                podetail4.Deadline = row.Deadline4;

                                delete row.Description4;
                                delete row.SubDescription4;
                                delete row.Quantity4;
                                delete row.Rate4;
                                delete row.Tax4;
                                delete row.TotalAmount4;
                                delete row.Deadline4;
                                row.PODetails.push(podetail4);
                            }

                            if (row.Description5 || row.Quantity5 || row.TotalAmount5) {
                                let podetail5 = {};
                                podetail5.Description = row.Description5;
                                podetail5.SubDescription = row.SubDescription5;
                                podetail5.Quantity = row.Quantity5;
                                podetail5.Rate = row.Rate5;
                                podetail5.Tax = row.Tax5;
                                podetail5.TotalAmount = row.TotalAmount5;
                                podetail5.Deadline = row.Deadline5;

                                delete row.Description5;
                                delete row.SubDescription5;
                                delete row.Quantity5;
                                delete row.Rate5;
                                delete row.Tax5;
                                delete row.TotalAmount5;
                                delete row.Deadline5;
                                row.PODetails.push(podetail5);
                            }
                            let validatedRow = validatePO(row);
                            if (validatedRow.status) {
                                valid.push(row);
                            }
                            else {
                                invalid.push(row);
                            }
                            dddd.push(validatedRow);
                        });
                        setData(dddd);
                        setInvalidData(invalid);
                        setValidData(valid);
                    }
                };
                reader.readAsArrayBuffer(e.target.files[0]);
            }
            else {
                e.target.value = "";
                setInvalidFile(true);
                setInvalidFile(true);
                setValidated(true);
            }
        }
        else {
            setInvalidFile(true);
            setValidated(true);
        }
    };

    const handleSubmit = () => {
        const callImport = () => {
            setLoading(true);
            fetch(state.projectdetail.API + "/POAdmin/Import?projectId=" + inputValue.ProjectId + "&TempleateId=" + inputValue.FkPOTemplateId, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(validData)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setKey("summarySelection");
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }
        if (invalidData.length > 0) {
            window.confirm("You have invalid data are you sure to import only valid data (" + validData.length + " rows)", function () {
                callImport();
            });
        }
        else {
            callImport();
        }
    }
    const [key, setKey] = useState('fileSelection');
    const handleNext = () => {
        if (key == "fileSelection") {
            setValidated(true);
            if (!inputValue.ProjectId || !inputValue.FkPOTemplateId) {
                return;
            }
            setKey("dataSelection");
        }
    }
    const [invalidFile, setInvalidFile] = useState(false);

    const downloadInvalid = () => {
        let invalidDownaldableData = [];
        data.forEach(function (row) {
            let invalidObj = JSON.parse(JSON.stringify(row));            
            if (invalidObj.PODetails) {
                invalidObj.PODetails.forEach(function (detailrow, index) {
                    if (detailrow.Description) {
                        invalidObj["Description" + (index + 1)] = detailrow.Description;
                    }
                    if (detailrow.SubDescription) {
                        invalidObj["SubDescription" + (index + 1)] = detailrow.SubDescription;
                    }
                    if (detailrow.Quantity) {
                        invalidObj["Quantity" + (index + 1)] = detailrow.Quantity;
                    }
                    if (detailrow.Rate) {
                        invalidObj["Rate" + (index + 1)] = detailrow.Rate;
                    }
                    if (detailrow.Tax) {
                        invalidObj["Tax" + (index + 1)] = detailrow.Tax;
                    }
                    if (detailrow.TotalAmount) {
                        invalidObj["TotalAmount" + (index + 1)] = detailrow.TotalAmount;
                    }
                    if (detailrow.Deadline) {
                        invalidObj["Deadline" + (index + 1)] = detailrow.Deadline;
                    }
                });
                delete invalidObj.PODetails;
            }
            invalidDownaldableData.push({ ...invalidObj });
        })
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_json(ws, invalidDownaldableData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: "biff8", type: 'array', cellStyles: true });
        const finalData = new Blob([excelBuffer]);
        FileSaver.saveAs(finalData, "InvalidRecordsData.xlsx");
    }
    return (
        <Tabs
            defaultActiveKey={key}
            id="uncontrolled-tab-example" activeKey={key}
            className="mb-3" onSelect={(k) => setKey(k)}
        >
            <Tab eventKey="fileSelection" title="File Selection">
                <Form noValidate validated={validated}>
                    <Row className="mb-3">
                        <Col md={6}>
                            <a href="/POTemplate.xls" target="_blank" >Download Template <Icon name="BsDownload" /></a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Row>
                                <Form.Group>
                                    <Form.Label>Project<code className='required-symbol'>*</code></Form.Label>
                                    <DynamicDropdownSearchable required={true} addAttr={["PONumberFormat"]} from={state.projectdetail.API + "/Project/GetAll"} label="ProjectName" value="Id" fetchType="get" name="ProjectId" onDropdownChange={handleProjectSelect} defaultval={inputValue.ProjectId} />
                                    {validated && !inputValue.ProjectId && <div className='invalid-msg'>Select project</div>}
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Form.Group>
                                    <Form.Label>PO Template<code className='required-symbol'>*</code></Form.Label>
                                    <DynamicDropdownSearchable required={true} from={state.projectdetail.API + "/POTemplate/GetActive"} label="POTemplateName" value="Id" fetchType="get" name="FkPOTemplateId" onDropdownChange={handleChange} defaultval={inputValue.FkPOTemplateId} />
                                    {validated && !inputValue.FkPOTemplateId && <div className='invalid-msg'>Select PO Template</div>}
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Row>
                                <Form.Group>
                                    <Form.Label>Choose Document<code className='required-symbol'>*</code></Form.Label>
                                    <input
                                        type="file"
                                        name="upload"
                                        id="upload"
                                        onChange={onFileChange}
                                    />
                                    {validated && invalidFile && <div className='invalid-msg'>Select xls file with proper format</div>}
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Form.Group>
                                    <Form.Label>PO Number Format</Form.Label>
                                    <div>
                                        <Form.Label>{inputValue.PONumberFormat}</Form.Label>
                                    </div>
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-3 float-end">
                        <Col className='text-center'>
                            <Button variant="primary" type="button" onClick={handleNext} >
                                Next
                            </Button>
                        </Col>
                    </Row>
                    {/*<Row className="mb-3">*/}
                    {/*    <Col className='text-center'>*/}
                    {/*        <Button variant="primary" type="submit" disabled={loading}>*/}
                    {/*            Submit*/}
                    {/*            {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}*/}
                    {/*        </Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Form>
            </Tab>
            <Tab eventKey="dataSelection" title="Data" disabled>
                <Row className="mb-3">
                    <Col md={4}>

                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        Total Records:{data.length}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Valid Records:{validData.length}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Invalid Records:{invalidData.length}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>

                    </Col>
                </Row>
                <DataGrid options={options} customData={data} columns={columns} />
                <Row className="mb-3 float-end">
                    <Col className='text-center'>
                        <Button variant="primary" type="button" onClick={handleSubmit} >
                            Next
                        </Button>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="summarySelection" title="Summary" disabled>

                <Row className="mb-3">
                    <Col md={4}>

                    </Col>
                    <Col md={4}>
                        <Row className="mb-3">
                            <Col>
                                <Alert variant={"success"}>
                                    Data imported successfully.
                                </Alert>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        Total Records:{data.length}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Total imported Records:{validData.length}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Invalid Records:{invalidData.length}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>

                    </Col>
                </Row>
                <h4>Invalid Records</h4>
                <a onClick={downloadInvalid} target="_blank" >Download invalid records <Icon name="BsDownload" /></a>
                <DataGrid options={options} customData={invalidData} columns={columns} />
            </Tab>
        </Tabs>


    )
}
export default POImport;