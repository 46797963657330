import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Button, Form, Row, Col, Modal, CloseButton } from 'react-bootstrap'
import Icon from '../../controls/Icons/Icon';
import UserForm from '../User/UserForm';
import './Profile.css';
import ResetPasswordForm from '../Login/ResetPasswordForm';
const Profile = () => {
    const state = useSelector(state => state);
    const [enableEdit, setEnableEdit] = useState(false);
    const [inputValue, setInputValue] = useState(state.access.user);

    const enableEditing = () => {
        setEnableEdit(!enableEdit);
    }
    const afterSave = (res) => {
        if (res.Succeeded) {
            setEnableEdit(!enableEdit);
        }
    }
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const ChangePassword = () => {
        setShowModal(true);
    }
    const onCancelPassword = () => {
        setShowModal(false);
    }
    const afterReset = () => {
        window.alert("Password changed successfully");
        handleClose();
    }
    return (
        <>

            <div className='mb-4'>
                <h4 className='form-heading d-lg-inline-block d-md-inline-block d-xl-inline-block d-block'>
                    Employee Profile
                </h4>
                <div className='d-lg-inline-block d-md-inline-block d-xl-inline-block d-block float-md-end'>
                    <Button onClick={ChangePassword}>Change Password</Button>&nbsp;
                    <Button onClick={enableEditing}>Edit &nbsp;<Icon name="AiOutlineForm" /></Button>
                </div>
            </div>
            <Card>
                <Card.Body>
                    <h5 className='form-sub-heading'>
                        {enableEdit ? "Edit " : ""}Employee Profile
                    </h5>
                    <fieldset disabled={!enableEdit} className={(enableEdit ? "show-submit" : "") + " user"}>
                        <UserForm onCancel={enableEditing} _inputValue={inputValue} afterSave={afterSave} showPassword={false} disableForProfile={true} />
                    </fieldset>

                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Body>
                    <ResetPasswordForm mobile={state.access.user.mobile} heading={"Change Password"} afterReset={afterReset} showCancel={true} onCancel={onCancelPassword} />
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Profile;