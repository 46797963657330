import React from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const LoginForm = () => {
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [inputValue, setInputValue] = useState({
        username: "",
        password: ""
    });
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        setErrorMessage("");
        setShowErrorMessage(false);
        setLoading(true);
        fetch(state.projectdetail.API + "/auth/login", {
            method: "POST",
            body: JSON.stringify(inputValue),
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        }).then(res => res.json()).then((res) => {            
            if (res.Succeeded) {
                localStorage.setItem("user", JSON.stringify(res.Data));
                navigate("/admin/dashboard");
            }
            else {
                setErrorMessage(res.Message);
                setShowErrorMessage(true);
            }

        }).catch((err) => {
            setErrorMessage(state.projectdetail.errorMessage);
            setShowErrorMessage(true);
        }).finally(() => {
            setLoading(false);
        });
    })
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col>
                    <Alert show={showErrorMessage} variant="danger" onClose={() => setShowErrorMessage(false)} dismissible>
                        {errorMessage}
                    </Alert>
                </Col>
            </Row>
            <Row className="mb-4">
                <Form.Group>
                    <Form.Label>User ID<code className='required-symbol'>*</code></Form.Label>
                    <Form.Control required type="text" placeholder='Mobile Number/ Email Adderss' name="username" value={inputValue.username} onChange={handleChange} autoComplete="off" />
                    <Form.Control.Feedback type="invalid">Enter your Mobile Number or Email Address</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group>
                    <Form.Label>Password<code className='required-symbol'>*</code></Form.Label>
                    <Form.Control required type="password" placeholder='Enter your Password' name="password" value={inputValue.password} onChange={handleChange} autoComplete="off" />
                    <Form.Control.Feedback type="invalid">Enter your Password</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Col>
                    <Button className='w-100' variant="primary" type="submit" disabled={loading}>
                        Login
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default memo(LoginForm);
