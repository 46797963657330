import { memo, forwardRef, useRef, useImperativeHandle } from 'react';
import React, { useEffect, useState } from 'react'
import './FileUpload.css'
import { useSelector } from 'react-redux';
import { Row, Col, Badge, CloseButton, Button, Spinner } from 'react-bootstrap';
import Icon from '../Icons/Icon';
import { getExtension, getFileIcon, getFileNamewithDate, getFileSize, validateFile } from "../../../Utils.js";
import FileUploadViewUploaded from './FileUploadViewUploaded';
import FileUploadViewNotUploaded from './FileUploadViewNotUploaded';
const FileUpload = forwardRef(({ folderpath, uploadedFiles, ext, displayContent, multiple, imageUpload, previewstyle, onChange, uploadOnChange, layoutstyle }, ref) => {
    if (!displayContent) {
        displayContent = "Drag and drop your files or click here to browse";
    }
    if (!ext) {
        ext = ["png", "jpg", "pdf", "jpeg","doc","docx"];
    }
    useEffect(() => {
        if (uploadedFiles) {
            setUploadedFileNames(uploadedFiles.split(","));
        }
        else {
            setUploadedFileNames([]);
        }
    }, [uploadedFiles])

    const state = useSelector(state => state);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadedFileNames, setUploadedFileNames] = useState([]);
    const [invalidFiles, setInvalidFiles] = useState([]);
    const [tobeDeleteFilesname, setTobeDeleteFilesname] = useState([]);
    const [tobeUploadedFilesnames, setTobeUploadedFilesnames] = useState([]);
    const [imgSrc, setImgSrc] = useState("");
    const fileInput = useRef();

    const upload = (callback) => {
        setLoading(true);
        deleteFile(function () {
            if (files.length > 0) {
                const formData = new FormData();
                let fileNames = [];
                for (let floop = 0; floop < files.length; floop++) {
                    let file = files[floop].file;
                    if (validateFile(file, ["png", "jpg", "pdf", "jpeg","doc","docx"])) {
                        let fileNamewithDate = getFileNamewithDate(file.name);
                        formData.append(folderpath + fileNamewithDate, file, fileNamewithDate);
                        fileNames.push(fileNamewithDate);
                    }
                }
                fetch(state.projectdetail.API + "/filesadmin/upload", {
                    method: "POST",
                    body: formData
                }).then(response => response.json()).then((res) => {                   
                    if (res.Succeeded) {
                        let finalFilesList = []
                        if (!multiple) {
                            finalFilesList = [...res.Data];
                        }
                        else {
                            finalFilesList = [...uploadedFileNames, ...res.Data];
                        }
                        setUploadedFileNames(finalFilesList);
                        setFiles([]);
                        debugger;
                        if (typeof callback == "function")
                            callback(finalFilesList);
                    }
                    else {
                        window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                    }
                }).catch((err) => {
                    setLoading(false);
                    window.alert(err);
                }).finally(() => {
                    setLoading(false);
                });
            }
            else {
                if (typeof callback == "function")
                    callback(uploadedFileNames);
                setLoading(false);
            }
        })
    }
    const deleteFile = (callback) => {
        if (tobeDeleteFilesname.length > 0) {
            let obj = {}
            obj.folderpath = folderpath;
            obj.filenames = tobeDeleteFilesname;
            fetch(state.projectdetail.API + "/filesadmin/delete", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setTobeDeleteFilesname([]);
                    if (typeof callback == "function")
                        callback();
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                setLoading(false);
                window.alert(err);
            });
        }
        else {
            if (typeof callback == "function")
                callback();
        }
    }
    useImperativeHandle(ref, () => ({
        upload,
        deleteFile,
        getUploadedFiles() {
            return uploadedFileNames;
        },
        changeFile() {
            triggerFile();
        },
    }));

    const triggerFile = () => {
        fileInput.current.click();
    }
    useEffect(() => {
        if (files.length > 0 && uploadOnChange) {
            upload(onChange);
        }
        else {
            if (files.length > 0) {
                if (typeof onChange == "function")
                    onChange(files);
            }
        }
    }, [files])
    const fileChange = (e) => {
        if (e.target.files) {
            if (multiple) {
                let fileArray = [];
                for (let floop = 0; floop < e.target.files.length; floop++) {
                    let file = e.target.files[floop];
                    if (validateFile(file, ext)) {
                        let obj = {
                            type: getExtension(file.name),
                            filename: file.name,
                            filesize: file.size,
                            file: file
                        };
                        fileArray.push(obj);
                    }
                    else {
                        setInvalidFiles(...invalidFiles, ...[file.name]);
                    }
                }
                setFiles([...files, ...fileArray]);
            }
            else {
                setUploadedFileNames([]);
                let file = e.target.files[0];
                if (validateFile(file, ext)) {
                    let obj = {
                        type: getExtension(file.name),
                        filename: file.name,
                        filesize: file.size,
                        file: file
                    };
                    setFiles([obj]);
                    setInvalidFiles([]);
                    if (imageUpload) {
                        var reader = new FileReader();
                        var url = reader.readAsDataURL(file);
                        reader.onloadend = function (e) {
                            setImgSrc([reader.result]);
                        }.bind(this);

                    }
                }
                else {
                    setInvalidFiles(...[file.name]);
                }
            }
            fileInput.current.value = "";
        }
    }


    const removeFile = (index) => {
        let fff = [...files];
        fff.splice(index, 1);
        if (fff.length == 0) {
            setFiles([]);
        }
        else {
            setFiles([...fff]);
        }
        // let tobeUp = [...tobeUploadedFilesnames];
        // tobeUp.splice(index, 1);
        // setTobeUploadedFilesnames(...tobeUp);
    }

    const removeUploadedFile = (index) => {
        let fff = [...uploadedFileNames];
        let tobeDeleteLst = JSON.parse(JSON.stringify(tobeDeleteFilesname))
        tobeDeleteLst.push(fff[index]);
        debugger
        setTobeDeleteFilesname(tobeDeleteLst);
        fff.splice(index, 1);
        if (fff.length == 0) {
            setUploadedFileNames([]);
        }
        else {
            setUploadedFileNames([...fff]);
        }
    }
    return (
        <div style={layoutstyle}>
            {loading ? <div style={previewstyle} className='fileupload'>
                <div className="fileupload-content d-flex justify-content-center align-items-center h-100 text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div>
            </div> :
                <div style={previewstyle} className={'fileupload ' + (files.length == 0 && uploadedFileNames.length == 0 ? "" : " border-0 ")}>


                    {(files.length == 0 && uploadedFileNames.length == 0) ?
                        <div className='fileupload-content d-flex justify-content-center align-items-center h-100 text-center' onClick={triggerFile}>
                            {displayContent}
                        </div> :

                        !imageUpload && <div className='fileupload-content p-2' onClick={triggerFile}>
                            {multiple ? "Add more" : "Change"}
                        </div>
                    }
                    {
                        uploadedFileNames.length > 0 &&
                        (!imageUpload ?
                            <div className='fileupload-files-uploaded p-2'>
                                {uploadedFileNames.map((fileName, index) => {
                                    return (
                                        <FileUploadViewUploaded folderpath={folderpath} key={index} fileName={fileName} onRemoveUploadedFile={removeUploadedFile} index={index} />
                                    )
                                })}
                            </div>
                            : <img src={state.projectdetail.API + "/filesadmin/load?path=" + folderpath + uploadedFileNames[0]} className="img-fluid img-preview img-thumbnail" />)
                    }
                    {
                        files.length > 0 &&
                        (!imageUpload ?
                            <div className='fileupload-files-tobeuploaded p-2'>
                                {files.map((fileObj, index) => {
                                    return (
                                        <FileUploadViewNotUploaded key={index} fileObj={fileObj} onRemove={removeFile} />
                                    )
                                })}
                            </div>
                            :
                            <img src={imgSrc} className="img-fluid img-preview img-thumbnail" />
                        )
                    }
                    <input type="file" name={multiple ? "inputEleFile[]" : "inputEleFile"} multiple={multiple} ref={fileInput} className="d-none" onChange={fileChange} />
                </div>
            }
            {(imageUpload && (files.length > 0 || uploadedFileNames.length > 0)) &&
                <div className='text-center mt-2'>
                    <a onClick={triggerFile}><Icon name="BsPencilFill" className='fs-6 me-2' />Edit Photo</a>
                </div>
            }
            {(invalidFiles.length > 0 && !multiple) ? <div className='invalid-msg'>Please select {ext.join(", ")} file and size should be below {getFileSize(state.projectdetail.maxFileSize)}</div> : ""}
            {(invalidFiles.length > 0 && multiple) ? <div className='invalid-msg'>Please select {ext.join(", ")} file and size should be below {getFileSize(state.projectdetail.maxFileSize)}</div> : ""}
        </div>
    )
});
export default memo(FileUpload);