import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';

import Icon from '../../controls/Icons/Icon';
import ServerError from '../../controls/ServerError';

export default function POTemplateForm({ _inputValue, afterSave, showPassword, disableForProfile, onCancel }) {
    const state = useSelector(state => state);
    const [showPasswordText, setShowPasswordText] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        Id: 0,
        POTemplateName: "",
        EffectiveFrom: null,
        Content: "",
        EmailSubject: "",
        SMSContent: "",
        AfterInvoiceRaisedEmailContent: "",
        AfterInvoiceRaisedEmailSubject: "",
        AfterInvoiceRaisedSMSContent: "",
        InvoiceApprovalEmailContent: "",
        InvoiceApprovalEmailSubject: "",
        InvoiceApprovalSMSContent: "",
        AfterPaidEmailContent: "",
        AfterPaidEmailSubject: "",
        AfterPaidSMSContent: "",
        fkRoleId: "",
        Active: 1,
    });
    const [serverError, setServerError] = useState({});
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);

    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            fetch(state.projectdetail.API + "/potemplate/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputValue)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    setInputValue({
                        POTemplateName: "",
                        EffectiveFrom: null,
                        Content: "",
                        EmailSubject: "",
                        SMSContent: "",
                        AfterInvoiceRaisedEmailContent: "",
                        AfterInvoiceRaisedEmailSubject: "",
                        AfterInvoiceRaisedSMSContent: "",
                        InvoiceApprovalEmailContent: "",
                        InvoiceApprovalEmailSubject: "",
                        InvoiceApprovalSMSContent: "",
                        AfterPaidEmailContent: "",
                        AfterPaidEmailSubject: "",
                        AfterPaidSMSContent: "",
                        fkRoleId: "",
                    });
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    })
    
   
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={12}>
                    <Card style={{ width: '100%' }}>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>PO Template name<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required type="text" name="POTemplateName" value={inputValue.POTemplateName} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">PO Template name is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>Effective from</Form.Label>
                                            <Form.Control required type="date"  name="EffectiveFrom" value={inputValue.EffectiveFrom}
                                                onChange={handleChange}
                                                autoComplete="off" />
                                        </Form.Group>
                                        <Form.Control.Feedback type="invalid">Effective from is invalid</Form.Control.Feedback>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            <Row className="mb-3">
                <Col md={12}>
                    <Card >
                        <Card.Title >PO Email</Card.Title>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>Email Subject<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required type="text" name="EmailSubject" value={inputValue.EmailSubject} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Email Subject is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>Email Content<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="Content" value={inputValue.Content} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Content is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>SMS Content<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="SMSContent" value={inputValue.SMSContent} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">SMS Content is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            <Row className="mb-3">
                <Col md={12}>
                    <Card >
                        <Card.Title >After Invoice Raised</Card.Title>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>Email Subject<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="AfterInvoiceRaisedEmailSubject" value={inputValue.AfterInvoiceRaisedEmailSubject} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">After Invoice Raised Email Subject is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>EmailContent<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="AfterInvoiceRaisedEmailContent" value={inputValue.AfterInvoiceRaisedEmailContent} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">After Invoice Raised Email Content is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>SMS Content<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="AfterInvoiceRaisedSMSContent" value={inputValue.AfterInvoiceRaisedSMSContent} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">After Invoice Raised SMS Content is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
           
            <Row className="mb-3">
                <Col md={12}>
                    <Card >
                        <Card.Title >Invoice Approval </Card.Title>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>Email Subject<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="InvoiceApprovalEmailSubject" value={inputValue.InvoiceApprovalEmailSubject} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Invoice Approval Email Subject is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>Email Content<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="InvoiceApprovalEmailContent" value={inputValue.InvoiceApprovalEmailContent} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Invoice Approval Email Content is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>SMS Content<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="InvoiceApprovalSMSContent" value={inputValue.InvoiceApprovalSMSContent} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Invoice Approval SMS Content is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>            

            <Row className="mb-3">
                <Col md={12}>
                    <Card >
                        <Card.Title >After Paid </Card.Title>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>Email Subject<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="AfterPaidEmailSubject" value={inputValue.AfterPaidEmailSubject} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">After Paid Email Subject</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                   
                                </Col>

                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>Email Content<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="AfterPaidEmailContent" value={inputValue.AfterPaidEmailContent} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">After Paid Email Content</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label>SMS Content<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required as="textarea" name="AfterPaidSMSContent" value={inputValue.AfterPaidSMSContent} onChange={handleChange} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">After Paid SMS Content</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>         
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <fieldset disabled={disableForProfile}>
                                <Form.Select value={inputValue.Active} name="Active" onChange={handleChange}>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </Form.Select>
                            </fieldset>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>

                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <ServerError error={serverError} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className='text-center'>
                    <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                        Cancel
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>&nbsp;&nbsp;
                    <Button variant="primary" type="submit" disabled={loading}>
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>

    )
}
