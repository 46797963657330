import { useState, memo, useRef, forwardRef, useImperativeHandle } from 'react';
import { Row, Form, Button, Col } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorHandler from '../ErrorHandler';
import { useSelector } from 'react-redux';
import DynamicDropdownSearchable from '../DynamicDropdownSearchable';
import MultiRangeSlider from '../MultiRangeSlider';
import DynamicMultiSelect from '../DynamicMultiSelect';

const DataGridFilter = forwardRef(({ columns, onFilter }, ref) => {

    useImperativeHandle(ref, () => ({
        clearFilter() {
            onClickClear();
        },
    }));
    const condtionTypeEnum = {
        Equals: 0,
        NotEquals: 1,
        GreaterThan: 2,
        LessThan: 3,
        Contains: 4,
        NotContains: 5,
        In: 6,
        NotIn: 7,
    };
    const state = useSelector(state => state);
    const defaults = useRef({
        inputType: "text", //text, number, drowdown, multiselect, tags, range, active
        conditionType: condtionTypeEnum.Contains,//contains, equal, startswith, endwith, between, in, notin


        rangeStart: "",
        rangeEnd: "",

        data: [],
        label: "",
        value: "",
        url: "",
        method: "",
        loading: false,
    });
    const [selectableInput, setSelectableInput] = useState(["dropdown", "multiselect"]);
    const [filterObj, setFilterObj] = useState([]);

    const handleChange = (e, col, filterOption) => {
        let name = "";
        let value = "";
        let maxLength = "";
        if (filterOption.inputType == "range") {
            name = col.field;
            value = [e.min, e.max];
        }
        else {
            name = e.target.name;
            value = e.target.value;
            maxLength = e.target.maxLength;

            if (e.target.type == "checkbox") {
                value = e.target.checked;
            }
            else {
                if (maxLength && maxLength > 0 && value.length > maxLength) {
                    return;
                }
            }
        }
        if (selectableInput.indexOf(filterOption.inputType) > -1 && value == filterOption.defaultval) {
            let objFilter = filterObj.filter((frow) => {
                return frow.Field != name;
            })
            setFilterObj(objFilter);
        }
        else if (filterOption.inputType == "range") {
            if (value[0] == filterOption.rangeStart && value[1] == filterOption.rangeEnd) {
                let objFilter = filterObj.filter((frow) => {
                    return frow.Field != name;
                })
                setFilterObj(objFilter);
            }
            else {
                let objFilter = filterObj.filter((frow) => {
                    return frow.Field == name;
                })
                let arrFilter = JSON.parse(JSON.stringify(filterObj));
                if (objFilter && objFilter.length > 0) {
                    arrFilter = filterObj.map((frow) => {
                        if (frow.Field == name) {
                            return { ...frow, FilterType: filterOption["conditionType"], Value: value }
                        }
                        return frow;
                    })
                }
                else {
                    arrFilter.push({ Field: name, FilterType: filterOption["conditionType"], Value: value });
                }
                setFilterObj(arrFilter);
            }
        }
        else {
            let objFilter = filterObj.filter((frow) => {
                return frow.Field == name;
            })
            let arrFilter = JSON.parse(JSON.stringify(filterObj));
            if (objFilter && objFilter.length > 0) {
                arrFilter = filterObj.map((frow) => {
                    if (frow.Field == name) {
                        return { ...frow, FilterType: filterOption["conditionType"], Value: value }
                    }
                    return frow;
                })
            }
            else {
                arrFilter.push({ Field: name, FilterType: filterOption["conditionType"], Value: value });
            }
            setFilterObj(arrFilter);
        }
    }
    const handleMultiSelect = (data, seleted, col, filterOption) => {
        let selectedValues = [];
        data.forEach(function (rw) {
            selectedValues.push(rw[filterOption["value"]]);
        });
        setFilterObj(c => ({
            ...c, [col.field]: {
                FilterType: filterOption["conditionType"],
                Value: selectedValues
            }
        }));
    }
    const getValue = (arr, column, filterOption) => {
        let obj = arr.filter((frow) => {
            return frow.Field == column.field;
        });
        if (obj && obj.length > 0) {
            if (obj[0] && obj[0].hasOwnProperty("Value")) {
                return obj[0].Value;
            }
        }        
        if (filterOption.inputType == "range") {
            return { min: filterOption.rangeStart, max: filterOption.rangeEnd };
        }
        return obj || "";
    }
    const applyFilter = () => {
        if (typeof onFilter === "function") {
            onFilter(filterObj);
        }
    }

    const onClickClear = () => {
        setFilterObj([]);
        if (typeof onFilter === "function") {
            onFilter([]);
        }
        setClearAll(c => !c);
    }
    const [clearAll, setClearAll] = useState(false);

    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            {columns.map((col) => {
                let input = "";
                let column = { ...defaults.current, ...col.filterOptions }
                if (col.filterable) {
                    if (selectableInput.indexOf(column.inputType) > -1) {
                        if (column.inputType == "multiselect") {
                            input = <Form.Group>
                                <Form.Label>{col.title}</Form.Label>
                                <DynamicMultiSelect reset={clearAll} customData={column.data} name={col.field} from={column.url} label={column.label} value={column.value} fetchType="get" onDropdownChange={(data, selected) => { handleMultiSelect.call(null, data, selected, col, column) }} />
                            </Form.Group>
                        }
                        else {
                            input = <Form.Group>
                                <Form.Label>{col.title}</Form.Label>
                                <DynamicDropdownSearchable refresh={clearAll} name={col.field} customData={column.data} from={column.url} label={column.label} value={column.value} fetchType="get" defaultval={getValue(filterObj[col.field], col, column)} onDropdownChange={(e) => { handleChange.call(null, e, col, column) }} />
                            </Form.Group>
                        }
                    }
                    else if (column.inputType == "active") {
                        input = <Form.Select name="active" value={getValue(filterObj[col.field], col, column)} onChange={(e) => { handleChange.call(null, e, col, column) }}>
                            <option value="">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </Form.Select>
                    }
                    else if (column.inputType == "range") {
                        input = <Form.Group>
                            <Form.Label>{col.title}</Form.Label>
                            <MultiRangeSlider reset={clearAll} min={column.rangeStart} max={column.rangeEnd} value={getValue(filterObj[col.field], col, column)} onChange={(e) => { handleChange.call(null, e, col, column) }} />
                        </Form.Group>
                    }
                    else {
                        input = <Form.Group>
                            <Form.Label>{col.title}</Form.Label>
                            <Form.Control type={column.inputType} size="sm" value={getValue(filterObj, col, column)} name={col.field} onChange={(e) => { handleChange.call(null, e, col, column) }} />
                        </Form.Group>
                    }
                    return (
                        <Row key={col.field} className="mb-2">
                            <Col>
                                {input}
                            </Col>
                        </Row>
                    )
                }
            })}
            <Row>
                <Col>
                    <Button variant="outline-primary" className='float-end' size="sm" onClick={applyFilter}>Apply</Button>
                    {Object.keys(filterObj).length > 0 ? <Button variant="outline-primary" className='float-end me-2' size="sm" onClick={onClickClear}>Clear</Button> : ""}
                </Col>
            </Row>
        </ErrorBoundary>
    )
});
export default memo(DataGridFilter);

export const condtionTypeEnum = {
    Equals: 0,
    NotEquals: 1,
    GreaterThan: 2,
    LessThan: 3,
    Contains: 4,
    NotContains: 5,
    In: 6,
    NotIn: 7,
};