import React, { memo } from "react";
import { useSelector } from 'react-redux';
function Footer() {
    const state = useSelector(state => state);
    const currentyear = new Date().getFullYear();
    return (
        <div className={" footer fixed-bottom"}>            
            <span className="float-end p-2">Powered by <a className="text-decoration-none fw-bold" href={state.projectdetail.PoweredByLink} target="_blank">{state.projectdetail.PoweredBy}</a></span>
        </div>
    );
}
export default memo(Footer);