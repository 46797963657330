import { useState, useContext, useEffect, useRef } from "react"
import { Card, Button, Modal, CloseButton, Spinner, Form, Row, Col, Badge } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useDispatch, useSelector } from 'react-redux';
import InvoiceForm from './InvoiceForm';
import Icon from "../../controls/Icons/Icon";
import { dateDisplayFormater } from "../../../Utils";
import { useNavigate } from "react-router-dom";
import PaymentForm from '../Payment/PaymentForm';
import CurrencyLabel from "../../controls/CurrencyLabel";

const Invoice = () => {
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState({
    });


    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const [showPOModal, setShowPOModal] = useState(false);
    const handlePOModalClose = () => setShowPOModal(false);
    const [POContent, setPOContent] = useState("");

    const [approvalInput, setApprovalInput] = useState({
        InvoiceId: 0,
        ApprovalStatus: "1",
        ApprovalRemarks: "",
    });
    const [approvalValidated, setApprovalValidated] = useState(false);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const handleApprovalModalClose = () => setShowApprovalModal(false);

    const handleAdd = () => {
        setInputValue([])
        setShowModal(true)
    };
    const handleEdit = (res) => {
        setInputValue(res);
        setShowModal(true);
    };
    const handleDelete = ((res) => {
        window.confirm("Do you want to delete the project?", function () {
            setLoading(true);
            let obj = {};
            obj.Id = res.Id;
            fetch(state.projectdetail.API + "/InvoiceAdmin/delete?Id=" + res.Id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            }).then(response => response.json()).then((res) => {
                {
                    if (res.Succeeded) {
                        window.alert("Deleted successfuly");
                        setRefreshGrid(!refreshGrid);
                    }
                    else {
                        window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                    }
                }
            }).catch((err) => {
                window.alert(state.projectdetail.ErrorMessage);
            }).finally(() => {
                setLoading(false);
            });
        })

    });
    const handleViewPO = (row) => {
        setLoading(true);
        fetch(state.projectdetail.API + "/POAdmin/GetPOContent?Id=" + row.fkPOId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        }).then(response => response.text()).then((res) => {
            setShowPOModal(true);
            setPOContent(res);
        }).catch((err) => {
            window.alert(err);
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleApproval = (row) => {
        setShowApprovalModal(true);
        row.InvoiceId = row.Id;
        setApprovalInput(row);
    };
    const handleApprovalChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setApprovalInput(c => ({ ...c, [name]: value }));
    });
    const handleApprovalSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setApprovalValidated(true);
        }
        else {
            debugger;
            let data = JSON.parse(JSON.stringify(approvalInput));
            data.ApprovalStatus = data.ApprovalStatus == "1";
            setLoading(true);
            fetch(state.projectdetail.API + "/InvoiceAdmin/InvoiceApproval", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setApprovalValidated(false);
                    setShowApprovalModal(false);
                    window.alert(res.Message);
                    setRefreshGrid(!refreshGrid);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const handleAfter = (res) => {
        if (res.Succeeded) {
            window.alert("Invoice saved successfully");
            handleClose();
            setRefreshGrid(!refreshGrid);
        }
        else {
            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
    };

    const columns = [
        {
            field: "po.PONo",
            title: "PO",
            filterable: true,
            formatter: function (cellContent, row) {
                return (<a onClick={() => handleViewPO(row)}>{cellContent}</a>);
            },
        },
        {
            field: "InvoiceDate",
            title: "Invoice Date",
            sortable: true,
            formatter: dateDisplayFormater
        },
        {
            field: "InvoiceNo",
            title: "Invoice No",
            filterable: true,
            sortable: true,
        },
        {
            field: "InvoiceAmount",
            title: "Invoice Amount",
            filterable: true,
            sortable: true,
            formatter: function (value) {
                return <CurrencyLabel text={value} />
            }
        },
        //{
        //    field: "InvoiceDetails",
        //    title: "Invoice Details",
        //    sortable: true,
        //},
        {
            field: "InvoiceDocument",
            title: "Invoice Document",
            sortable: true,
            formatter: function (cellContent, row) {
                return (<a onClick={() => handleViewInvoice(row)}>{cellContent}</a>);
            },
            //formatter: function (value) {
            //    return <a href={state.projectdetail.API + "/filesadmin/load?fileName=Invoices/" + value} target="_blank">{value}</a>
            //}
        },
        {
            field: "ApprovalStatus",
            title: "Approval Status",
            sortable: true,
            formatter: function (value, row) {
                if (value == true) {
                    return <Badge bg="success">Accepted</Badge>
                }
                else if (value == false) {
                    return <Badge bg="danger">Rejected</Badge>
                }
                else {
                    return <Badge bg="warning" onClick={() => handleApproval(row)}>Approval Pending</Badge>
                }
            },
        },
        {
            field: "Payment",
            title: "Payment Status",
            sortable: true,
            formatter: function (value, row) {
                if (row.ApprovalStatus == true) {
                    if (row.payments.length == 0)
                        return <Button className="btn btn-primary" onClick={() => handlePayment(row)}>Pay</Button>
                    else
                        return <Badge bg="success">Paid</Badge>
                }
                return "";
            },
        },
        {
            dataField: "remove",
            title: "",
            formatter: (cellContent, row) => {
                return (<div>
                    {state.access.edit ? <Button
                        className="btn-xs me-2 mb-2 p-0" variant="link"
                        onClick={() => handleEdit(row)}
                    ><Icon name="BsPencilFill" className='fs-5' /></Button> : ""}

                    {state.access.delete ? <Button variant="link"
                        className="btn-xs mb-2 p-0"
                        onClick={() => handleDelete(row)}
                    >
                        <Icon name="BsTrash" className='fs-5' />
                    </Button> : ""}
                </div>
                );
            },
        },
    ];

    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        sortName: "InvoiceDate",
        sortOrder: "desc",
        filter: true,
    };


    const handlePayment = (row) => {
        setShowPaymentModal(true);
    };
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const handlePaymentModalClose = () => setShowPaymentModal(false);
    const handleAfterPayment = (res) => {
        if (res.Succeeded) {
            window.alert("Payment details saved successfully");
            handlePaymentModalClose();
            setRefreshGrid(!refreshGrid);
        }
        else {
            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
        }
    };
    const handleViewInvoice = (row) => {
        window.open(state.projectdetail.API + "/filesadmin/load?fileName=Invoices/" + row.InvoiceDocument);
    };
    return (
        <>
            <h4 className='form-heading mb-4'>
                Invoices
                <div className='d-inline-block float-end'>
                    <Button onClick={handleAdd}>Add <Icon name="BsPersonPlus" /></Button>&nbsp;
                </div>
            </h4>
            <Card>
                <Card.Body>
                    {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}
                    <DataGrid url={state.projectdetail.API + "/InvoiceAdmin/grid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header>
                    <h4>{inputValue.Id ? "Edit " : "Add "} Invoice</h4>
                    <CloseButton onClick={handleClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <InvoiceForm afterSave={handleAfter} _inputValue={inputValue} showPassword={true} onCancel={handleClose} />
                </Modal.Body>
            </Modal>

            <Modal show={showPOModal} onHide={handlePOModalClose} size="lg">
                <Modal.Header>
                    <h4>Invoice Details</h4>
                    <CloseButton onClick={handlePOModalClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: POContent }} />
                </Modal.Body>
            </Modal>

            <Modal show={showApprovalModal} onHide={handleApprovalModalClose}>
                <Modal.Header>
                    <h4>Invoice Approval</h4>
                    <CloseButton onClick={handleApprovalModalClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={approvalValidated} onSubmit={handleApprovalSubmit}>
                        <Row className="mb-3">
                            <Col md={12}>
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Status<code className='required-symbol'>*</code></Form.Label>
                                        <Form.Select required value={approvalInput.ApprovalStatus} name="ApprovalStatus" onChange={handleApprovalChange}>
                                            <option value="">--Select--</option>
                                            <option value="1">Accept</option>
                                            <option value="0">Reject</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </Col>
                            <Col md={12}>
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control required type="text" name="ApprovalRemarks" value={approvalInput.ApprovalRemarks} onChange={handleApprovalChange} autoComplete="off" />
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className='text-center'>
                                <Button variant="outline-primary" className="me-2" disabled={loading}>
                                    Cancel
                                    {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                                </Button>
                                <Button variant="primary" type="submit" disabled={loading}>
                                    Save
                                    {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showPaymentModal} onHide={handlePaymentModalClose} size="lg">
                <Modal.Header>
                    <h4>Payment</h4>
                    <CloseButton onClick={handlePaymentModalClose} className="btn-close" />
                </Modal.Header>
                <Modal.Body>
                    <PaymentForm afterSave={handleAfterPayment} _inputValue={inputValue} showPassword={true} onCancel={handlePaymentModalClose} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Invoice;
