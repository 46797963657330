import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './components/pages/Login/Login';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Layout from './components/Layout';
import { Provider } from 'react-redux'
import { store } from './Reducer/store'
import User from './components/pages/User/User';
import Role from './components/pages/Role/Role';
import Profile from './components/pages/Profile/Profile';
import Designation from './components/pages/Designation/Designation';
import UserSettings from './components/pages/UserSettings/UserSettings';
import LayoutUnauthorized from './components/LayoutUnauthorized';
import SMSEmailTemplate from './components/pages/SMSEmailTemplate/SMSEmailTemplate';
import Home from './components/pages/Home/Home';
import Project from './components/pages/Project/Project';
import PO from './components/pages/PO/PO';
import ProjectDashboard from './components/pages/Project/ProjectDashboard';
import ProjectLayout from './components/pages/Project/ProjectLayout';
import SMSEmail from './components/pages/SMSEmail/SMSEmail';
import POSummary from './components/pages/PO/POSummary';
import PODetailView from './components/pages/PO/PODetailView';
import Invoice from './components/pages/Invoice/Invoice';
import POTemplate from './components/pages/POTemplate/POTemplate';
import IPBlockList from './components/pages/IPBlockList/IPBlockList';
import SMSEmailReport from './components/pages/SMSEmail/SMSEmailReport';
import Vendor from './components/pages/Vendor/Vendor';
function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LayoutUnauthorized />} title="Login">
                        <Route index path="/home" element={<Home />} title="Login" />
                        <Route index path="/login" element={<Login />} title="Login" />
                    </Route>
                    <Route exact path="/admin" element={<Layout />}>
                        {/* <Route exact path="/admin" element={<Admin />}> */}
                        <Route path="/admin/dashboard" element={<Dashboard />} title="Dashboard" />
                        <Route path="/admin/role" element={<Role />} title="Role" />
                        <Route path="/admin/user" element={<User />} title="User" />
                        <Route exact path="/admin/project" element={<Project />} title="Project" />
                        <Route path="/admin/po" element={<PO />} title="PO" />
                        <Route path="/admin/potemplate" element={<POTemplate />} title="PO Template" />
                        <Route path="/admin/invoice" element={<Invoice />} title="Invoice" />
                        <Route path="/admin/po/:id" element={<PODetailView />} />
                        {/*<Route path="/admin/project/:id" element={<ProjectLayout />} title="ProjectLayout">*/}
                        {/*    <Route path="/admin/project/:id/dashboard" element={<ProjectDashboard />} title="ProjectDashboard" />*/}
                        {/*    <Route path="/admin/project/:id/po" element={<PO />} title="PO" />                            */}
                        {/*</Route>                        */}
                        <Route path="/admin/profile" element={<Profile />} title="Profile" />
                        <Route path="/admin/designation" element={<Designation />} title="Designation" />
                        <Route path="/admin/settings" element={<UserSettings />} title="settings" />
                        <Route path="/admin/smsemailtemplate" element={<SMSEmailTemplate />} title="SMSEmailTemplate" />
                        <Route path="/admin/smsemail" element={<SMSEmail />} title="SMSEmail" />
                        <Route path="/admin/posummary" element={<POSummary />} title="POSummary" />
                        <Route path="/admin/ipblocker" element={<IPBlockList />} title="ipblock" />                        
                        <Route path="/admin/smsemailreport" element={<SMSEmailReport />} title="SMS Email Report" />
                        <Route path="/admin/Vendor" element={<Vendor />} title="Vendor" />
                        {/* </Route> */}
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}
export default App;
