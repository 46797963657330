import { Form, Spinner } from "react-bootstrap";
import { useState, useEffect, memo } from "react";
import ErrorHandler from './ErrorHandler'
const DynamicDropdownSearchable = ({ customData, from, label, value, fetchType, name, onDropdownChange, defaultval, required, addAttr, refresh, disabled }) => {
    const [data, setData] = useState([]);
    const [refreshDropdown, setRefreshDropdown] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    var opts = {};
    if (required) {
        opts['required'] = 'required';
    }
    useEffect(() => {
        try {
            if (from) {
                if (!label) {
                    label = "label";
                }
                if (!value) {
                    value = "value";
                }
                let obj = {};
                obj.method = fetchType;
                obj.credentials = "include";
                if (obj.method.toLowerCase() == "post") {
                    obj.headers = {
                        "Content-Type": "application/json"
                    };
                }

                setLoading(true);
                fetch(from, obj).then(response => response.json()).then((res) => {
                    setData(res)
                }).finally(() => {
                    setLoading(false);
                });
            }
            else if (customData && customData.length > 0) {
                setData(customData);
            }
            else {
                setData([]);
            }
        }
        catch (error) {
            setError(error);
        }
    }, [refreshDropdown, refresh]);

    const getValue = (obj, key) => {
        if (key.indexOf(".") > 0) {
            let keys = key.split(".");
            let nextKeys = JSON.parse(JSON.stringify(keys));
            nextKeys.shift();
            return getValue(obj[keys[0]], nextKeys.join("."));
        }
        else {
            if (obj.hasOwnProperty(key)) {
                return obj[key];
            }
            return "";
        }
    }
    if (error) {
        return <ErrorHandler error={error} />
    }
    return (
        <div className="position-relative">
            <Form.Select disabled={(disabled || loading)} aria-label="Default select example" name={name} {...opts} onChange={onDropdownChange} value={defaultval}>
                <option value="">--Select--</option>
                {data.map((row) => {
                    if (addAttr) {
                        let attr = {};
                        addAttr.forEach(function (attrKey) {
                            attr["data-" + attrKey.toLowerCase()] = row[attrKey];
                        });
                        return <option {...attr} value={getValue(row, value)} key={getValue(row, value)}>{getValue(row, label)}</option>
                    }
                    else {
                        return <option value={getValue(row, value)} key={getValue(row, value)}>{getValue(row, label)}</option>
                    }

                })}
            </Form.Select>
            {loading ? <Spinner className='dropdown-loading' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
        </div>
    );
}

export default memo(DynamicDropdownSearchable)