import React from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import "./PageSkeleton.css";
const PageSkeleton = () => {
    return (
        <div className="sk-page">
            <div className="text-center">
                <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
            </div>
            {/* <h4 className='sk-page-heading'>
                <span className='sk-page-heading-text'><span className='invisible'>Heading Heading</span></span>
                <div className='d-inline-block float-end'>
                    <Button><span className='invisible'>Add</span> </Button>
                </div>
            </h4>
            <Card>
                <Card.Body>
                    <div className='mb-2'>
                        <div className='d-inline-block float-end'>
                            <Button className='mx-2'><span className='invisible'>A</span> </Button>
                            <Button><span className='invisible'>A</span> </Button>
                        </div>
                    </div>
                    <div>
                        <div className='sk-page-row'></div>
                        <div className='sk-page-row'></div>
                        <div className='sk-page-row'></div>
                        <div className='sk-page-row'></div>
                        <div className='sk-page-row'></div>
                        <div className='sk-page-row'></div>
                    </div>
                </Card.Body>
            </Card> */}
        </div>
    );
}

export default PageSkeleton;
