export const AccessAction = {
    setAccess: "SetAccess",
    setMenus: "SetMenus",
    resetAccess: "resetAccess",
}
const initialAccess = {
    menuName: "",
    path: "/",
    authorizeMenu: false,
    showMenu: false,
    view: true,
    add: true,
    edit: true,
    delete: true,
    export: true,
    menus: [],
};

const accessReducer = (access = initialAccess, actions) => {
    switch (actions.type) {
        case AccessAction.setAccess:
            // console.log("===============");
            // console.log(actions.payload.edit);
            // console.log("============");
            return { ...access, ...actions.payload };
        case AccessAction.setMenus:
            return Object.assign({}, access, { menus: actions.payload });
        case AccessAction.resetAccess:
            return Object.assign({}, initialAccess);
        default:
            return access;
    }
}
export default accessReducer;