import { memo } from "react";
import { CloseButton, Col, Row } from "react-bootstrap";
import { getFileIcon, getFileSize } from "../../../Utils";
import Icon from "../Icons/Icon";

const FileUploadViewNotUploaded = ({ onRemove, index, fileObj }) => {    
    return (
        <>
            <div className='border-bottom' key={index}>
                <Row>
                    <Col className='col-auto pe-0 fileupload-files-tobeuploaded-icon'>
                        <Icon name={getFileIcon(fileObj.type)} className="fs-2" />
                    </Col>
                    <Col className='col-8'>
                        <div className='fileupload-files-tobeuploaded-filename text-truncate'>
                            <span className=''>{fileObj.filename}</span>
                        </div>
                        <div className='fileupload-files-tobeuploaded-filesize'>
                            <label>{getFileSize(fileObj.filesize)}</label>
                        </div>
                    </Col>
                    <Col className='col-auto d-flex justify-content-center align-items-center'>
                        <CloseButton onClick={() => { onRemove.call(null, index) }} />
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default memo(FileUploadViewNotUploaded);