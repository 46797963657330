import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, NavDropdown, Form } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import ErrorHandler from '../ErrorHandler';
import MenuSkeleton from '../../skeleton/MenuSkeleton'
import Icon from '../Icons/Icon';
import "./Menu.css";
import Offcanvas from 'react-bootstrap/Offcanvas';
import LoggedUserDetail from './LoggedUserDetail';
const Menu = ({ show, showHideSidebar, open, showHideSidebarMobile, openMobile, mobieView }) => {
    const state = useSelector(state => state);
    const [menus, setMenus] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [showUserProfile, setShowUserProfile] = useState(false);

    if (!show) {
        return "";
    }
    if (error) {
        return <ErrorHandler error={error} />
    }
    const handleUserProfileClose = () => setShowUserProfile(false);
    const handleUserProfileShow = () => setShowUserProfile(true);
    return (
        <>
            {loading ? <MenuSkeleton /> :
                <div>
                    <Navbar className={"navbar menu p-0"} expand="lg">
                        <Navbar.Brand className='heading text-uppercase px-2 h2 m-0'>
                            {state.projectdetail.ProjectLogo}
                            {!mobieView ? state.projectdetail.ProjectName : ""}

                            {mobieView ? <span className='ms-2'>
                                <Nav.Link onClick={showHideSidebarMobile}>
                                    {(open ? <span><Icon name="AiOutlineMenuFold" /></span> : <Icon name="AiOutlineMenuUnfold" />)}
                                </Nav.Link>
                            </span> : ""}                            
                        </Navbar.Brand>
                        <Navbar className='w-100'>
                            <Nav className="me-auto">
                                {/* <Nav.Item className='d-none d-md-inline-block'>
                                <Form.Control className='search' type="text" placeholder='Search' value={search} onChange={handleSearch} name="search" autoComplete="off" />
                            </Nav.Item> */}
                            </Nav>
                            <Nav>
                                {/* <Nav.Item>
                                    <Nav.Link><Icon name="BsFillBellFill" /></Nav.Link>
                                </Nav.Item> */}

                                {/* <Nav.Item>
                                    <Nav.Link onClick={handleUserProfileShow}>
                                        <span>
                                            {state.access.user.photo ? <img src={("../profile/" + state.access.user.photo)} width="35" /> : <Icon name="BsPersonCircle" />}
                                            &nbsp;
                                            <span className='username d-none d-md-inline-block'>{state.access.user && state.access.user.name}</span>
                                        </span>
                                    </Nav.Link>
                                </Nav.Item> */}

                                <Nav.Item>
                                    <Nav.Link>
                                        <span className='username d-none d-md-inline-block'>Hi, {state.access.user && state.access.user.name}</span>
                                    </Nav.Link>
                                </Nav.Item>


                                {/* <NavDropdown align="end" title={
                                <span>
                                    {state.access.user.photo ? <img src={("../profile/" + state.access.user.photo)} width="35" /> : <Icon name="BsPersonCircle" />}
                                    &nbsp;
                                    <span className='username d-none d-md-inline-block'>{state.access.user && state.access.user.name}</span>
                                </span>}>
                                <NavDropdown.Item>
                                    <Nav.Link as={NavLink} to="/admin/profile"><Icon name="BsPerson" />&nbsp; Profile</Nav.Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={logout}>
                                    <Nav.Link>
                                        <Icon name="BsPower" />&nbsp;Logout
                                    </Nav.Link>
                                </NavDropdown.Item>
                            </NavDropdown> */}
                            </Nav>
                        </Navbar>
                    </Navbar>


                    <Offcanvas placement="end" show={showUserProfile} onHide={handleUserProfileClose} responsive="lg">
                        <Offcanvas.Body className='p-0'>
                            <LoggedUserDetail handleClose={handleUserProfileClose} />
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            }
        </>
    );
}
export default memo(Menu);