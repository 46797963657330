import React, { useState, useEffect } from 'react';
import { Toast, Row, Col, Modal, Button } from 'react-bootstrap'
import Icon from '../Icons/Icon';
import "./AlertPrompt.css";
const AlertPrompt = ({ options }) => {
    if (!options.ButtonText) {
        options.ButtonText = "Ok";
    }
    const handleOk = () => {
        window.hideAlert();
        if (typeof options.onYes == "function") {
            options.onYes();
        }
    }
    return (
        <Modal show={options.show} className={"alert-message " + options.varient} centered>
            <Modal.Body>
                <Row className='mb-3'>
                    <Col className='alert-message-icon'>
                        {options.icon ? options.icon : <Icon name="BsCheckCircleFill" />}
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col className='alert-message-message'>
                        {options.message}
                    </Col>
                </Row>
                {options.additionalcontent ? <Row className='mb-3'>
                    <Col className='alert-message-message'>
                        {options.additionalcontent}
                    </Col>
                </Row> : ""}
                <Row className='mb-3'>
                    <Col className='alert-message-buttons'>
                        <Button onClick={handleOk}>{options.ButtonText}</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default AlertPrompt;
