import { useState, useEffect, memo } from "react";
import ErrorHandler from './../ErrorHandler'
import { Form, Spinner } from "react-bootstrap";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const FormSmartText = ({ from, label, value, formatParams, fetchType, onSelect, formatRenderItem, defaultval, required, disabled, name, selectedObj }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = (query) => {
        setIsLoading(true);
        let reqParams = {};
        if (typeof formatParams == "function") {
            reqParams = formatParams(query);
        }
        let url = from;
        if (fetchType.toLowerCase() == "get") {
            url += "?" + new URLSearchParams(reqParams);
        }
        let obj = {};
        obj.method = fetchType;
        obj.credentials = "include";
        if (obj.method.toLowerCase() == "post") {
            obj.headers = {
                "Content-Type": "application/json"
            };
        }
        fetch(url, obj).then(resp => resp.json()).then(json => {
            setIsLoading(false);
            setOptions(json);
        });
    };
    const filterBy = () => true;

    return (
        <AsyncTypeahead
            disabled={disabled}
            id={name}
            required={required}
            filterBy={filterBy}
            isLoading={isLoading}
            labelKey={label}
            onSearch={handleSearch}
            options={options}
            onChange={obj => {
                if (obj && obj.length > 0)
                    onSelect(obj[0]);
            }}
            onFocus={() => {
                if (selectedObj[label] && (!options || options.length == 0))
                    handleSearch(selectedObj[label]);
            }}
            defaultInputValue={selectedObj[label] ? selectedObj[label] : ""}
            renderMenuItemChildren={(option) => {
                if (formatRenderItem && typeof formatRenderItem == "function") {
                    return formatRenderItem(option)
                }
                return option[label];
            }}
        />
    );
}
export default memo(FormSmartText);