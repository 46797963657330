import React, { memo } from 'react'

function ServerError({ error }) {
    if (error) {        
        let errorList = [];
        Object.keys(error).forEach(function (err) {
            if (typeof error[err] == "string") {
                errorList.push(error[err]);
            }
        });
        const processMessage = (input) => {
            return input;
        }
        if (errorList.length == 0) {
            return <div></div>
        }
        return (
            <ul class='text-danger'>
                {errorList.map((errorMessage) => {
                    return <li key={errorMessage}>{processMessage(errorMessage)}</li>
                })}
            </ul>
        )
    }
    else {
        return <div></div>
    }

}

export default memo(ServerError);