import { Popover, Button } from "react-bootstrap";
import React, { memo, useEffect, useState } from "react"
import Icon from "../../controls/Icons/Icon";
import { useSelector } from "react-redux";


const BlockOrUnblockIPPopover = React.forwardRef(
    ({ popper, children, show: _, ip: ip, ProjectId: ProjectId, ...props }, ref) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [children, popper]);

        const [alreadyBlocked, setAlreadyBlocked] = useState(false);
        useEffect(() => {
            fetch(state.projectdetail.API + "/IPBlockList/GetByIP?ip=" + ip + "&ProjectId=" + ProjectId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(response => response.json()).then((res) => {
                if (res && res.length > 0) {
                    setAlreadyBlocked(true);
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }, [ip]);
        const state = useSelector(state => state);
        const [loading, setLoading] = useState(false);
        const BlockIP = () => {
            window.confirm("Are you sure to " + (alreadyBlocked ? "unblock" : "block") + " this IP Address (" + ip + ")?", function () {
                setLoading(true);
                if (!alreadyBlocked) {
                    let inputValue = {
                        id: 0,
                        IPAddress: ip,
                        ProjectId: ProjectId,
                    };
                    fetch(state.projectdetail.API + "/IPBlockList/Block", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(inputValue)
                    }).then(response => response.json()).then((res) => {
                        if (res.Succeeded) {
                            window.alert("IP address blocked successfully");
                            setAlreadyBlocked(true);
                        }
                        else {
                            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                        }
                    }).catch((err) => {
                        window.alert(err);
                    }).finally(() => {
                        setLoading(false);
                    });
                }
                else {
                    fetch(state.projectdetail.API + "/IPBlockList/Unblock?ip=" + ip + "&ProjectId=" + ProjectId, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }).then(response => response.json()).then((res) => {
                        if (res.Succeeded) {
                            window.alert("IP address unblocked successfully");
                            setAlreadyBlocked(false);
                        }
                        else {
                            window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                        }
                    }).catch((err) => {
                        window.alert(err);
                    }).finally(() => {
                        setLoading(false);
                    });
                }
            });
        }
        return (
            <Popover ref={ref} body {...props}>
                <Button onClick={BlockIP}>{alreadyBlocked ? "Unblock IP" : "Block IP"}</Button>
            </Popover>
        );
    },
);


export default memo(BlockOrUnblockIPPopover);
