import React, { memo, useSelector } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import "./SidebarMenuSkeleton.css";
const SidebarMenuSkeleton = ({ open }) => {
    return (
        <Nav className={"col-md-12 sk " + (open ? "" : " closed px-2")}>
            <div className='container p-0'>
                <div className={"sk-sidebar-menu"}>
                    <Row>
                        <Col>
                            <div className='sk-sidebar-menu-icon'></div>
                            <div className='sk-sidebar-menu-link'></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='sk-sidebar-menu-icon'></div>
                            <div className='sk-sidebar-menu-link'></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className='sk-sidebar-menu-icon'></span>
                            <span className='sk-sidebar-menu-link'></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className='sk-sidebar-menu-icon'></span>
                            <span className='sk-sidebar-menu-link'></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className='sk-sidebar-menu-icon'></span>
                            <span className='sk-sidebar-menu-link'></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className='sk-sidebar-menu-icon'></span>
                            <span className='sk-sidebar-menu-link'></span>
                        </Col>
                    </Row>
                </div>
            </div>
        </Nav>
    )
}

export default memo(SidebarMenuSkeleton);