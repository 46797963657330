import { useState, useContext, useEffect, useRef, memo } from "react"
import { Form, Button, Modal, CloseButton, Spinner, Row, Col } from 'react-bootstrap';
import DataGrid from '../../controls/DataGrid.js/DataGrid'
import { useSelector } from 'react-redux';
import Icon from "../../controls/Icons/Icon";
import SMSEmailLogModal from "./SMSEmailLogModal";
import { DataFilterType, SMSOrEmail, convertDateToSet } from "../../../Utils";
import { condtionTypeEnum } from "../../controls/DataGrid.js/DataGridFilter";

const SMSEmailSummaryProjectWise = () => {
    const state = useSelector(state => state);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [smsoremail, setSmsoremail] = useState(3);    
    const columns = [
        {
            field: "ProjectName",
            title: "Project",
            sortable: true,
        },
        {
            field: "TotalEmailCredits",
            title: "Total Email Credits Used",
            sortable: true,
            formatter: function (value, row) {
                return <a>{value}</a>;
            },
        },
        {
            field: "TotalSMSCredits",
            title: "Total SMS Credits Used",
            sortable: true,
            formatter: function (value, row) {
                return <a>{value}</a>;
            },
        },
        {
            field: "TotalTried",
            title: "Total Tried",
            sortable: true,
            formatter: function (value, row) {
                return <a onClick={() => openLogAll(row)}>{value}</a>;
            },
        },
        {
            field: "TotalEmail",
            title: "Total Email Tried",
            sortable: true,
            formatter: function (value, row) {
                return <a onClick={() => openLogAllEmails(row)}>{value}</a>;
            },
        },
        {
            field: "TotalEmailSuccess",
            title: "Total Email Success",
            sortable: true,
            formatter: function (value, row) {
                return <a onClick={() => openLogSuccessEmails(row)}>{value}</a>;
            },
        },
        {
            field: "TotalEmailFailed",
            title: "Total Email Failed",
            sortable: true,
            formatter: function (value, row) {
                return <a onClick={() => openLogFailedEmails(row)}>{value}</a>;
            },
        },

        {
            field: "TotalSMS",
            title: "Total SMS Tried",
            sortable: true,
            formatter: function (value, row) {
                return <a onClick={() => openLogAllSMS(row)}>{value}</a>;
            },
        },
        {
            field: "TotalSMSSuccess",
            title: "Total SMS Success",
            sortable: true,
            formatter: function (value, row) {
                return <a onClick={() => openLogSuccessSMS(row)}>{value}</a>;
            },
        },
        {
            field: "TotalSMSFailed",
            title: "Total SMS Failed",
            sortable: true,
            formatter: function (value, row) {
                return <a onClick={() => openLogFailedSMS(row)}>{value}</a>;
            },
        },
        {
            field: "StartDate",
            title: "Start Date",
            filterable: true,
            filterOptions: { inputType: "date", conditionType: condtionTypeEnum.GreaterThan },
            visible: false,
        },
        {
            field: "EndDate",
            title: "End Date",
            filterable: true,
            filterOptions: { inputType: "date", conditionType: condtionTypeEnum.LessThan },
            visible: false,
        },
    ];

    const options = {
        dataType: "server",
        showExport: true,
        showRefresh: true,
        filter: true,
        sortName: "ProjectName",
        sortOrder: "asc",
    };


    const [logFilter, setLogFilter] = useState([]);
    const [showLog, setShowLog] = useState(false);

    const openLogAll = (row) => {
        setLogFilter(
            [
                { Field: "ProjectId", FilterType: DataFilterType.Equals, Value: row.ProjectId }
            ]
        );
        setSmsoremail(SMSOrEmail.Both);
        setShowLog(true);
    };

    const openLogAllEmails = (row) => {
        setLogFilter(
            [
                { Field: "EmailFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "ProjectId", FilterType: DataFilterType.Equals, Value: row.ProjectId }
            ]
        );
        setSmsoremail(SMSOrEmail.Email);
        setShowLog(true);
    };
    const openLogFailedEmails = (row) => {
        setLogFilter(
            [
                { Field: "EmailFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "EmailStatus", FilterType: DataFilterType.Equals, Value: false },
                { Field: "ProjectId", FilterType: DataFilterType.Equals, Value: row.ProjectId }
            ]);
        setSmsoremail(SMSOrEmail.Email);
        setShowLog(true);
    };
    const openLogSuccessEmails = (row) => {
        setLogFilter(
            [
                { Field: "EmailFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "EmailStatus", FilterType: DataFilterType.Equals, Value: true },
                { Field: "ProjectId", FilterType: DataFilterType.Equals, Value: row.ProjectId }
            ]);
        setSmsoremail(SMSOrEmail.Email);
        setShowLog(true);
    };

    const openLogAllSMS = (row) => {
        setLogFilter(
            [
                { Field: "SMSFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "ProjectId", FilterType: DataFilterType.Equals, Value: row.ProjectId }
            ]
        );
        setSmsoremail(SMSOrEmail.SMS);
        setShowLog(true);
    };
    const openLogFailedSMS = (row) => {
        setLogFilter(
            [
                { Field: "SMSFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "SMSStatus", FilterType: DataFilterType.Equals, Value: false },
                { Field: "ProjectId", FilterType: DataFilterType.Equals, Value: row.ProjectId }
            ]);
        setSmsoremail(SMSOrEmail.SMS);
        setShowLog(true);
    };
    const openLogSuccessSMS = (row) => {
        setLogFilter(
            [
                { Field: "SMSFlag", FilterType: DataFilterType.Equals, Value: true },
                { Field: "SMSStatus", FilterType: DataFilterType.Equals, Value: true },
                { Field: "ProjectId", FilterType: DataFilterType.Equals, Value: row.ProjectId }
            ]);
        setSmsoremail(SMSOrEmail.SMS);
        setShowLog(true);
    };
    return (
        <div>
            {loading ? <div className="text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /> </div> : ""}            
            <DataGrid url={state.projectdetail.API + "/SMSEmailAdmin/ProjectWiseSummaryGrid"} classes="table table-striped" options={options} columns={columns} refresh={refreshGrid} />
            {showLog && <SMSEmailLogModal show={showLog} filter={logFilter} smsoremail={smsoremail} />}
        </div>
    );
}

export default memo(SMSEmailSummaryProjectWise);
