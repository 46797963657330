import React, { memo } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
const AccessDenied = () => {
    return (
        <Container>
            <Row>
                <Col md={4}>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body className='text-center'>
                            <Card.Title><h1>403</h1></Card.Title>
                            <Card.Text>
                                <h3>Access Denied</h3>
                                <h4>This page is restricted for you </h4>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default memo(AccessDenied);
