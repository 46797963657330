import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import DynamicDropdownSearchable from '../../controls/DynamicDropdownSearchable';
import Icon from '../../controls/Icons/Icon';
import ServerError from '../../controls/ServerError';
export default function ProjectForm({ _inputValue, afterSave, onCancel }) {
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState({
        Id: 0,
        ProjectName: "",
        SendSMS: false,
        SendMail: false,
        SenderMailId: "",
        FkSMSConfigId: 0,
        FkEmailConfigId: 0,
        URL: "",
        DateFormat: "",
        PONumberFormat: "",

        SMSUsername: "",
        SMSPassword: "",
        SMSApiKey: "",
        SMSSenderId: "",
        PaymentTerms: "",
        TermConditions: ""
    });
    const [serverError, setServerError] = useState({});
    const handleChange = ((e) => {
        const name = e.target.name;
        let value = e.target.value;
        let maxLength = e.target.maxLength;
        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }
        else {
            if (maxLength && maxLength > 0 && value.length > maxLength) {
                return;
            }
        }
        setInputValue(c => ({ ...c, [name]: value }));
    });
    useEffect(() => {
        setInputValue({ ...inputValue, ..._inputValue });
    }, []);

    const handleSubmit = ((e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            setLoading(true);
            fetch(state.projectdetail.API + "/project/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputValue)
            }).then(response => response.json()).then((res) => {
                if (res.Succeeded) {
                    setValidated(false);
                    setInputValue({
                        Id: 0,
                        ProjectName: "",
                        URL: "",
                    });
                    afterSave(res);
                }
                else {
                    window.alert(res.Message, function () { }, <Icon name='BsFillEmojiFrownFill' />, "Ok", "danger");
                }
            }).catch((err) => {
                window.alert(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    })
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Project Name<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control required type="text" name="ProjectName" value={inputValue.ProjectName} onChange={handleChange} autoComplete="off" />
                            <Form.Control.Feedback type="invalid">Project Name is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="text" name="URL" value={inputValue.URL} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Email configuration<code className='required-symbol'>*</code></Form.Label>
                            <DynamicDropdownSearchable from={state.projectdetail.API + "/EmailConfiguration/GetAll"} label="ConfigurationName" value="Id" fetchType="get" name="FkEmailConfigId" onDropdownChange={handleChange} defaultval={inputValue.FkEmailConfigId} />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>SMS Configuration<code className='required-symbol'>*</code></Form.Label>
                            <DynamicDropdownSearchable from={state.projectdetail.API + "/SMSConfiguration/GetAll"} label="ConfigurationName" value="Id" fetchType="get" name="FkSMSConfigId" onDropdownChange={handleChange} defaultval={inputValue.FkSMSConfigId} />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Send Email</Form.Label>
                            <Form.Check name="SendMail" checked={inputValue.SendMail} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Send SMS</Form.Label>
                            <Form.Check name="SendSMS" checked={inputValue.SendSMS} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>From Mail Id</Form.Label>
                            <Form.Control type="email" name="SenderMailId" value={inputValue.SenderMailId} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Date display format</Form.Label>
                            <Form.Control type="text" name="DateFormat" value={inputValue.DateFormat} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>PO Format</Form.Label>
                            <Form.Control type="text" name="PONumberFormat" value={inputValue.PONumberFormat} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Payment Terms<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control type="text" name="PaymentTerms" value={inputValue.PaymentTerms} onChange={handleChange} autocomplete="false" readonly onfocus="this.removeAttribute('readonly');" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">               
                <Col md={12}>
                    <Row>
                        <Form.Group>
                            <Form.Label>Teams & Conditions<code className='required-symbol'>*</code></Form.Label>
                            <Form.Control type="text" name="TermConditions" value={inputValue.TermConditions} onChange={handleChange} autocomplete="false" readonly onfocus="this.removeAttribute('readonly');" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>           
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>SMS Username (This is get from service provider)</Form.Label>
                            <Form.Control type="password" name="SMSUsername" value={inputValue.SMSUsername} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>SMS Password (This is get from service provider)</Form.Label>
                            <Form.Control type="password" name="SMSPassword" value={inputValue.SMSPassword} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>SMS API (This is get from service provider)</Form.Label>
                            <Form.Control type="password" name="SMSApiKey" value={inputValue.SMSApiKey} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Form.Group>
                            <Form.Label>SMS Sender Id (This is get from service provider)</Form.Label>
                            <Form.Control type="text" name="SMSSenderId" value={inputValue.SMSSenderId} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <ServerError error={serverError} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className='text-center'>
                    <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
                        Cancel
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>&nbsp;&nbsp;
                    <Button variant="primary" type="submit" disabled={loading}>
                        Save
                        {loading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : ""}
                    </Button>
                </Col>
            </Row>
        </Form>

    )
}
