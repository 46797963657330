import React, { memo, useEffect, useState } from 'react'
import { Form, Row, Col, Button, Spinner, InputGroup, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { POAction } from '../../../Reducer/POReducer';
import { convertDateToSet } from '../../../Utils';
import Icon from '../../controls/Icons/Icon';
function PODetailsForm() {

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const setPODetails = function (data) {
        dispatch({ type: POAction.setPODetails, payload: data })
    }
    const emptyDescription = {
        Description: "",
        SubDescription: "",
        Quantity: null,
        Rate: null,
        Tax: null,
        TotalAmount: null,
        Deadline: "",
    };
    const AddDescription = function () {
        let descs = JSON.parse(JSON.stringify(state.po.PODetails));
        descs.push(JSON.parse(JSON.stringify(emptyDescription)));
        setPODetails(descs);
    }
    const RemoveDescription = function (irow, key) {
        dispatch({ type: POAction.deletePODetail, payload: { index: key, row: irow } });
    }
    const handleChange = (e, row, index) => {
        let name = e.target.name;
        let value = e.target.value;
        setPODetails(state.po.PODetails.map((drow, dindex) => {
            if (dindex == index) {
                return { ...drow, [name]: value };
            }
            else {
                return { ...drow };
            }
        }));
    }

    return (
        <div>
            {/*<Table>
        //    <thead>
        //        <tr>
        //            <th>DESCRIPTION</th>
        //            <th>QTY</th>
        //            <th>RATE</th>
        //            <th>TAX</th>
        //            <th>AMOUNT</th>
        //        </tr>
        //    </thead>
        //    <tbody>
        //        {PODetails.map(function (row) {
        //            return (
        //                <tr>
        //                    <td>

        //                    </td>
        //                </tr>)
        //        })}
        //    </tbody>
        //</Table>*/}

            {
                state.po.PODetails.map(function (row, index) {
                    return (
                        <div className='p-3 border mb-3' key={"PODetails" + index}>
                            <Row>
                                <Col md={4}>
                                    <Row className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Description<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required
                                                type="text"
                                                name="Description"
                                                value={row.Description}
                                                onChange={(e) => { handleChange.call(null, e, row, index) }}
                                                autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Description is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Row className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Sub Description</Form.Label>
                                            <Form.Control type="text" name="SubDescription" value={row.SubDescription}
                                                onChange={(e) => { handleChange.call(null, e, row, index) }}
                                                autoComplete="off" />
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Row className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Deadline<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required type="date" name="Deadline" value={convertDateToSet(row.Deadline)}
                                                onChange={(e) => { handleChange.call(null, e, row, index) }}
                                                autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Deadline is invalid</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Quantity<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required type="text" name="Quantity" value={row.Quantity}
                                                onChange={(e) => { handleChange.call(null, e, row, index) }}
                                                autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Quantity is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Rate<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required type="text" name="Rate" value={row.Rate}
                                                onChange={(e) => { handleChange.call(null, e, row, index) }}
                                                autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Rate is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Tax<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required type="text" name="Tax" value={row.Tax}
                                                onChange={(e) => { handleChange.call(null, e, row, index) }}
                                                autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Tax is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Total Amount<code className='required-symbol'>*</code></Form.Label>
                                            <Form.Control required type="text" name="TotalAmount" value={row.TotalAmount}
                                                onChange={(e) => { handleChange.call(null, e, row, index) }}
                                                autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">Total Amount is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Button
                                        className="btn-xs me-2 mb-2 p-0"
                                        variant="link"
                                        onClick={() => { RemoveDescription.call(null, row, index) }}>
                                        <Icon name="BsTrash" />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )
                })
            }
            <div>
                <a onClick={AddDescription}><Icon name="AiOutlinePlus" /> Add more items</a>
            </div>
        </div>
    );
}
export default memo(PODetailsForm);