import { memo } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
function ErrorHandler({ error }) {
    const state = useSelector(state => state);
    return (
        <Alert variant="danger">
            <Alert.Heading>Oh oh! You got an error!</Alert.Heading>
            <hr />
            <p>
                {state.IsDevMode ? error.message : "Please try after some time"}
            </p>
        </Alert>
    )
}
export default memo(ErrorHandler);